import React from "react";
import HomePage from "../pages/home/DashboardPage";
import ResultPage from "../pages/results/ResultsPage";
import { RouteType } from "./config";
import CyclesPage from "../pages/cycles/CyclesPage";
import DevicesPage from "../pages/devices/DevicesPage";
import BatchesPage from "../pages/batches/BatchesPage";
import AboutPage from "../pages/home/AboutPage";
import UsersPage from "../pages/users/UsersPage";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import RotateRightOutlinedIcon from '@mui/icons-material/RotateRightOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ResultsPage from "../pages/results/ResultsPage";

const appRoutes: RouteType[] = [
    {
        index: true,
        element: <HomePage />,
        state: "home"
    },
    {
        path: "/dashboard",
        element: <HomePage />,
        state: "dashboard",
        sidebarProps: {
          displayText: "Dashboard",
          icon: <InsertChartOutlinedIcon fontSize="large"/>
        },
      },
      {
        path: "/users",
        element: <UsersPage />,
        state: "users",
        sidebarProps: {
          displayText: "Users",
          icon: <PeopleAltOutlinedIcon fontSize="large"/>
        },
      },
    {
      path: "/cycles",
      element: <CyclesPage />,
      state: "cycles",
    },
    {
      path: "/devices",
      element: <DevicesPage />,
      state: "devices",
      sidebarProps: {
        displayText: "Devices",
        icon: <PhoneAndroidOutlinedIcon fontSize="large"/>
      },
    },
    {
      path: "/results",
      element: <ResultsPage />,
      state: "results"
    },
    {
      path: "/batches",
      element: <BatchesPage />,
      state: "batches",
      sidebarProps: {
        displayText: "Batches",
        icon: <AppsOutlinedIcon fontSize="large"/>
      },
    },
    {
      path: "/about",
      element: <AboutPage />,
      state: "about",
      sidebarProps: {
        displayText: "About",
        icon: <InfoOutlinedIcon fontSize="large"/>
      },
    },
]

export default appRoutes;