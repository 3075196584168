import { useState } from "react";
import { MaterialReactTable, MRT_Row, type MRT_ColumnDef, type MRT_SortingState } from 'material-react-table';
import dayjs from 'dayjs';
import { Box, Button, Grid, Link, ListItemIcon, Menu, MenuItem, MenuProps, Paper, Tooltip, Typography, alpha, styled } from '@mui/material';
import assets from "../../../assets";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CssBaseline from '@mui/material/CssBaseline';
import { DeviceSessionStorageKey, DeviceSessionStorageModel, DeviceTableParameterStorageService } from "../../../services/sessionStorage";
import RefreshIcon from '@mui/icons-material/Refresh';
import colorConfigs from "../../../configs/colorConfigs";
import { tsXLXS } from 'ts-xlsx-export';

const StyledMenu = styled((props: any) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


interface DevicesListProps {
    data: any[];
    isLoading: boolean;
    sorting: any;
    columnVisibility: any;
    columnFilters: any;
    pagination: any;
    globalFilter: any;
    showGlobalFilter: any;
    needToRefreshData: boolean;
    setShowGlobalFilter: (value: any) => void;
    handleRemoveAllFilters: () => void;
    setGlobalFilter: (value: string) => void;
    setSorting: (sorting: any[]) => void;
    setColumnVisibility: (columnVisibility: { [key: string]: boolean }) => void;
    setColumnFilters: (filters: any[]) => void;
    setPagination: (pagination: { pageSize: number; pageIndex: number }) => void;
    setDevice: (detail: any) => void;
    setPopup: (detail: any) => void;
    fetchData: () => void;
  }

const DevicesList: React.FC<DevicesListProps> = ({
  data,
  isLoading,
  sorting,
  columnVisibility,
  columnFilters,
  pagination,
  globalFilter,
  showGlobalFilter,
  needToRefreshData,
  setShowGlobalFilter,
  setGlobalFilter,
  handleRemoveAllFilters,
  setSorting,
  setColumnVisibility,
  setColumnFilters,
  setPagination,
  setDevice,
  setPopup,
  fetchData
}) => {
    const navigate = useNavigate()
    
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleSaveFilterClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const columns: MRT_ColumnDef<any>[] = [
        {
            accessorKey: 'id',
            header: 'ID',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            ID<br />&nbsp;
            </div>, 
        },
        {
            accessorKey: 'model',
            header: 'Model',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            Model<br />&nbsp;
            </div>, 
        },
        {
            accessorKey: 'is_active',
            header: 'Active',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            Active<br />&nbsp;
            </div>, 
            Cell: ({ cell }) => (
                <div style={{display: "flex", justifyContent: "center"}}>
                  {cell.getValue()?.toString() == "true" ? <Typography fontSize="smaller" sx={{ paddingX:0.7, border: `1.5px solid ${colorConfigs.buttons.active}`, borderRadius: 2, color: colorConfigs.buttons.active }} >Active</Typography>: <Typography fontSize="smaller" sx={{ paddingX:0.7, border: `1.5px solid ${colorConfigs.buttons.inactive}`, borderRadius: 2, color: colorConfigs.buttons.inactive }} >Inactive</Typography> }
                </div>
              ),
        },
    ]


    // Define the type for the keys for handling persistance of table settings in session storage
    type DeviceSessionStorageKeyType = keyof DeviceSessionStorageModel;

    // Generic handler function for persisting table parameters
    const handleParameterChange = <T,>(
        key: DeviceSessionStorageKeyType,
        currentValue: T,
        setValueFunction: (value: T) => void,
        newValue: T | ((prevValue: T) => T)
        ) => {
        const updatedValue = typeof newValue === 'function' ? (newValue as (prevValue: T) => T)(currentValue) : newValue;
        DeviceTableParameterStorageService.set(key, updatedValue);
        setValueFunction(updatedValue);
        };

    // Handlers for persisting table: sorting, pagination, visibility, filter, search, and search bar visibility
    const setSortingFunction = (newSortingValue: any) =>
        handleParameterChange(DeviceSessionStorageKey.sorting, sorting, setSorting, newSortingValue);
    const setPaginationChangeFunction = (newPaginationValue: any) =>
        handleParameterChange(DeviceSessionStorageKey.pagination, pagination, setPagination, newPaginationValue);
    const setColumnVisibilityFunction = (newColumnVisibilityValue: any) =>
        handleParameterChange(DeviceSessionStorageKey.columnVisibility, columnVisibility, setColumnVisibility, newColumnVisibilityValue);
    const setFilterChangeFunction = (newFilterChangeValue: any) =>
        handleParameterChange(DeviceSessionStorageKey.columnFilters, columnFilters, setColumnFilters, newFilterChangeValue);
    const setGlobalFilterChangeFunction = (newGlobalFilterChangeValue: string | ((prevValue: string) => string)) =>
        handleParameterChange(DeviceSessionStorageKey.search, globalFilter, setGlobalFilter, newGlobalFilterChangeValue);
    const setShowGlobalFilterChangeFunction = (newShowGlobalFilterChangeValue: any) =>
        handleParameterChange(DeviceSessionStorageKey.showSearch, showGlobalFilter, setShowGlobalFilter, newShowGlobalFilterChangeValue);



    return <MaterialReactTable
            columns={columns}
            data={data}
            state={{
              isLoading,
              sorting,
              columnVisibility,
              columnFilters,
              pagination,
              globalFilter,
              showGlobalFilter
            }}
            onSortingChange={setSortingFunction}
            onColumnVisibilityChange={setColumnVisibilityFunction}
            onColumnFiltersChange={setFilterChangeFunction}
            onPaginationChange={setPaginationChangeFunction}
            onGlobalFilterChange={setGlobalFilterChangeFunction}
            onShowGlobalFilterChange={setShowGlobalFilterChangeFunction}
            defaultColumn={{    
              minSize: 10,
              maxSize: 160,
              size: 10,
            }}
            positionActionsColumn="last"
            enableRowSelection
            selectAllMode="all"



            positionPagination="top"
            enableBottomToolbar={false}
            initialState={{
              sorting,
              columnVisibility,
              columnFilters,
              pagination,
              globalFilter,
              density: 'compact',
              columnPinning: { left: ['mrt-row-select'], right: ['mrt-row-actions']},
            }}

          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: "Cycles", // Renames Actions Column
            },
          }}

          muiTableContainerProps={{
            // TABLE CONTAINER
              sx: {
                height: 'auto', // Allow the table to expand naturally
                maxHeight: 'calc(100vh - 300px)', // Sets height of the table to fix it in position              
              }
          }}

          muiTopToolbarProps={({ table }) => ({
            // TOP TOOLBAR (Export Data + filter buttons)
            sx: {
              position: 'sticky', // Pins Top Toolbar on scroll
              top: 0,
              zIndex: 1,
              overflowY: 'hidden',
              p: 0.5,
              paddingBottom: 0,
              color: colorConfigs.sidebar.color,
              '& .MuiToolbar-root': {
                width: "50%",
                padding: 0,
                margin: 0,
                paddingBottom: 0,
                paddingTop: 0,
              },
              '& .MuiBox-root':{
                p:0
              },
              '& .MuiIconButton-root': {
                color: colorConfigs.tables.headBg,
              },
              '& .MuiTextField-root': {
                p: 0,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: colorConfigs.tables.headBg,
                  },
                  '&:hover fieldset': {
                    borderColor: colorConfigs.tables.headBg,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colorConfigs.tables.headBg,
                  },
                },
                '& .MuiInputBase-input': {
                  color: colorConfigs.tables.headBg,
                },
                '& .MuiInputAdornment-root .MuiSvgIcon-root': {
                  color: colorConfigs.tables.headBg,
                },
              },
              '& .MuiTablePagination-root': {
                margin: 0,
                paddingTop: 0.5,
                paddingBottom: 0.5,
                color: colorConfigs.buttons.bg,
              },
              '& .MuiTablePagination-selectLabel': {
                color: colorConfigs.tables.headBg,
              },
              '& .MuiTablePagination-displayedRows': {
                color: colorConfigs.buttons.bg,
              },
              '& .MuiTablePagination-actions': {
                color: colorConfigs.tables.headBg,
              },
              '& .MuiInputAdornment-root .MuiSvgIcon-root': {
                color: colorConfigs.tables.headBg,
              },
            },
          })}

          muiTableHeadProps={{
            // WHOLE TABLE HEADER
            sx: {
              position: 'sticky',
              top: 0,
              zIndex: 1,
              overflowY: 'hidden',
            }
          }}

          muiTableHeadCellProps={({ column }) => ({
            // TABLE HEADER CELLS
            sx: {
              border: '1px solid rgba(255, 255, 255, 0.3)',
              backgroundColor: colorConfigs.tables.headBg,
              opacity: 0.95,
              color: "white",
              paddingBottom:0.2,
              paddingTop:0.2,
              ...(column.id === 'mrt-row-select' && { // Fix Select column width
                maxWidth: '50px',
                width: '50px',
              }),
              '& .MuiIconButton-root': {
                color: "white",
              },
              '& .MuiSvgIcon-root': {
                color: "white",
                p:0,
                m:0,
              },
              '& .MuiTableSortLabel-root': {
                color: 'white',
                // p:0,
                m:0,
                ml:1,
                mr: -0.5,
                '&.Mui-active': {
                  color: 'white',
                },
                '& .MuiTableSortLabel-icon': {
                  color: 'white !important',
                  marginLeft: 'auto',
                },
              },
              '& .MuiTableSortLabel-iconDirectionAsc': {
                color: 'white !important',
                p:0,
                m:0,
                ml:3
              },
              '& .MuiTableSortLabel-iconDirectionDesc': {
                color: 'white !important',
                p:0,
                m:0,
                ml:3
              },
            },
          })}
              
              
          muiFilterTextFieldProps={{
              // TABLE FILTER BUTTONS
              sx: {
                p:0,
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                color: "white",
                '& .MuiIconButton-root': {
                  color: "rgba(255, 255, 255, 0.5)",
                },
                '& .MuiInputBase-input': {
                  color: "white",
                  p: 0.5,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'none',
                },
                '& .MuiInput-underline:before': {
                  borderColor: 'none',
                },
                '&:hover .MuiInput-underline:before': {
                  borderColor: 'rgba(255, 255, 255, 0.5)',
                },
                '& .MuiInput-underline:after': {
                  borderColor: 'rgba(255, 255, 255, 0.5)',
                },
              },
            }}

          muiTableBodyRowProps={({ row }) => ({
              // Handles Row Click  
              onClick: (event) => {
                  console.log(row.original);
                  setDevice(row.original)
                  setPopup("Edit")
              },
              sx: {
                  cursor: 'pointer',
                  backgroundColor: row.index % 2 === 0 ? colorConfigs.tables.firstRow : colorConfigs.tables.secondRow,
                  paddingBottom:0,
                  paddingTop:0,
              },
          })}

          muiTableBodyProps={{
            // TABLE BODY
            sx: {
              maxHeight: "100%",
              overflowY: 'auto', // Allows body to scroll
              '&::-webkit-scrollbar': { display: 'none' }, // Remove scroll bar
              '-ms-overflow-style': 'none', // Internet Explorer 10+
              'scrollbar-width': 'none', // Firefox
            }
          }}

          muiTableBodyCellProps={({ cell }) => ({
            // TABLE BODY CELLS
            sx: {
                paddingBottom:1,
                paddingTop:1,
                ...(cell.column.id === 'mrt-row-select' && { // Fix Select column width
                  maxWidth: '50px',
                  width: '50px',
                }),
            },
          })}

          muiBottomToolbarProps={{
              // BOTTOM TOOLBAR (Footer - currently disabled)
          }}

          muiPaginationProps={{
            // PAGINATION TOOLBAR PROPS
            rowsPerPageOptions: [500, 1000],
            sx: {
              '& .MuiTablePagination-root': {
              },
              '& .MuiToolbar-root': {
              },
              '& .MuiTablePagination-selectLabel': {
              },
              '& .MuiTablePagination-displayedRows': {
              },
            }
          }}

          

          renderTopToolbarCustomActions={({ table }) => {

            const handleArchiveCycles = () => {
                table.getSelectedRowModel().flatRows.map(row => {
                    //Add archive logic
                });
            };

            const handleExportRows = (rows: any[]) => {
              var excel_rows = []
              var now = dayjs().format('YYYY-MM-DD HH:mm:ss')
              var filename = now + "_DeviceList"
      
              for (var x = 0; x < rows.length; x++) {
                var formattedExpiryDate = dayjs(rows[x].original.expiry_date).format('YYYY-MM-DD')
    
                excel_rows[x] = {
                  "ID": rows[x].original.id,
                  "Model": rows[x].original.model,
                  "Expiry Date": formattedExpiryDate,
                  "Active": rows[x].original.is_active ? "Yes": "No",
                  "Parameter A": rows[x].original.parameters?.a,
                  "Parameter B": rows[x].original.parameters?.a,
                  "Parameter C": rows[x].original.parameters?.a,
                  "Parameter D": rows[x].original.parameters?.a,
                }
              }
      
              tsXLXS().exportAsExcelFile(excel_rows).saveAsExcelFile(filename);
      
            };
                

                return (

                  <div style={{ width: showGlobalFilter ? "55%": "80%", display: 'flex', gap: '2rem', justifyContent: "space-between", transition: showGlobalFilter ? "width 0.1s ease-in" : "width 0.5s ease-out"}}>
                  <div style={{ position: 'relative', height: 'auto', display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                    <Button
                      fullWidth
                      onClick={() => setPopup("Create")}
                      variant="contained"
                      id="demo-customized-button3"
                      disableElevation
                      sx={{ 
                        background: colorConfigs.buttons.bg, 
                        color: 'white', 
                        minWidth: "15em",
                        width: "fit-content",
                        height: "fit-content",
                        textTransform: 'none', 
                        transition: 'transform 0.3s ease', 
                        fontWeight: 'bold', 
                        '&:hover': { transform: 'scale(1.03)' } 
                      }}                
                    >
                      Create Device
                    </Button>
                    <div style={{minWidth: "15.5em", height: "100%", position: 'absolute', top: '41px', display: "flex", gap: "0.4em"}}>      
                    <Button
                      id="demo-customized-button"
                      aria-controls={open ? 'demo-customized-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      variant="contained"
                      disableElevation
                      onClick={handleClick}
                      endIcon={<KeyboardArrowDownIcon />}
                      sx={{ width: "fit-content", background: colorConfigs.buttons.bg, color: "white", textTransform: 'none', transition: 'transform 0.3s ease', fontWeight: "bold", '&:hover': { transform: 'scale(1.03)' } }}
                    >
                      Export Data
                    </Button>
                    <Tooltip title="Refresh Data" placement="right">
                    <Button
                      id="demo-customized-button1"
                      variant="contained"
                      disableElevation
                      onClick={() => fetchData()}
                      sx={{ 
                        background: colorConfigs.buttons.bg, 
                        color: "white", 
                        textTransform: 'none', 
                        transition: 'transform 0.3s ease', 
                        fontWeight: "bold", '&:hover': { transform: 'scale(1.03)' },
                        animation: needToRefreshData ? 'growShrink 0.5s infinite alternate' : 'none',

                      }}
                    >
                      <RefreshIcon sx={{ p: 0, m: 0 }} />
                    </Button>
                    </Tooltip>
                    </div>  
                  </div>
                  <Button
                    id="demo-customized-button1"
                    variant="contained"
                    disableElevation
                    onClick={() => handleRemoveAllFilters()}
                    sx={{ background: colorConfigs.buttons.bg, color: "white", textTransform: 'none', transition: 'transform 0.3s ease', fontWeight: "bold", '&:hover': { transform: 'scale(1.03)' } }}
                  >
                    Reset Filters
                  </Button>
                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem disabled={
                        !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                      } onClick={() => handleExportRows(table.getSelectedRowModel().rows)} disableRipple>
                        Export to Excel
                      </MenuItem>
                    </StyledMenu>
                    </div>
                );
            }}
        />
}
 
export default DevicesList;

