import { ListItemButton, ListItemIcon } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";
import { RootState } from "../../redux/store";
import { RouteType } from "../../routes/config";

type Props = {
  item: RouteType;
  isHovered: boolean;
};

const SidebarItem = ({ item, isHovered }: Props) => {
  const { appState } = useSelector((state: RootState) => state.appState);
  return (
    item.sidebarProps && item.path ? (
      <ListItemButton
        component={Link}
        to={item.path}
        disableRipple
        selected={appState === item.state}
        sx={{
          "&:hover": {
            color: colorConfigs.sidebar.hoverBg,
            backgroundColor: colorConfigs.sidebar.bg,
          },
          "&.Mui-selected": {
            backgroundColor: colorConfigs.sidebar.bg,
            color: colorConfigs.sidebar.activeBg,
            "&:hover": {
              backgroundColor: colorConfigs.sidebar.bg,
            },
            "&:focus": {
              backgroundColor: colorConfigs.sidebar.bg,
            },
            "&:active": {
              backgroundColor: colorConfigs.sidebar.bg,
            },
          },
          "&.Mui-selected.Mui-focusVisible": {
            backgroundColor: colorConfigs.sidebar.bg,
          },
          "&.Mui-selected:active": {
            backgroundColor: colorConfigs.sidebar.bg,
          },
          "&.Mui-selected:focus": {
            backgroundColor: colorConfigs.sidebar.bg,
          },
          color: appState === item.state ? colorConfigs.sidebar.activeBg : "unset",
          paddingY: "25px",
        }}
      >
        <ListItemIcon sx={{
          minWidth: "unset",
          marginLeft: "7px",
          p:0,
          marginRight: "20px",
          justifyContent: "center",
          transition: "margin-right 0.3s",
          color: appState === item.state ? colorConfigs.sidebar.activeBg : "unset",
          "&:hover": {
            color: colorConfigs.sidebar.hoverBg,
            backgroundColor: colorConfigs.sidebar.bg,
          },
          "&.Mui-selected": {
            backgroundColor: colorConfigs.sidebar.bg,
            color: colorConfigs.sidebar.activeBg,
            "&:hover": {
              backgroundColor: colorConfigs.sidebar.bg,
            },
            "&:focus": {
              backgroundColor: colorConfigs.sidebar.bg,
            },
            "&:active": {
              backgroundColor: colorConfigs.sidebar.bg,
            },
          },
          "&.Mui-selected.Mui-focusVisible": {
            backgroundColor: colorConfigs.sidebar.bg,
          },
          "&.Mui-selected:active": {
            backgroundColor: colorConfigs.sidebar.bg,
          },
          "&.Mui-selected:focus": {
            backgroundColor: colorConfigs.sidebar.bg,
          },
        }}>
          {item.sidebarProps.icon && item.sidebarProps.icon}
        </ListItemIcon>
        {isHovered && item.sidebarProps.displayText}
      </ListItemButton>
    ) : null
  );
};

export default SidebarItem;