import { type MRT_SortingState } from 'material-react-table';

export interface iStorageService<T> {
    get<K extends keyof T>(key: K): T[K] | undefined;
  
    set<K extends keyof T>(key: K, value: T[K]): void;
  
    remove<K extends keyof T>(key: K): void;
  
    clear(): void;
  }

export class StorageService<T> implements iStorageService<T> {
    constructor(private readonly storage: Storage) {}

    set<K extends keyof T>(key: K, value: T[K]): void {
        this.storage.setItem(key.toString(), JSON.stringify(value));
    }

    get<K extends keyof T>(key: K): T[K] | undefined {
        const value = this.storage.getItem(key.toString());

        if (value === null || value === 'null'
        || value === undefined || value === 'undefined') {
        return undefined;
        }

        return JSON.parse(value);
    }
    remove<K extends keyof T>(key: K): void {
        this.storage.removeItem(key.toString());
    }

    clear(): void {
        this.storage.clear();
    }
}

// Remove Session Storage at logout
export const clearAllSessionStorage = () => {
    Object.values(ResultSessionStorageKey).forEach(key => {
      sessionStorage.removeItem(key);
    });
    Object.values(UserSessionStorageKey).forEach(key => {
        sessionStorage.removeItem(key);
    });
    Object.values(CycleSessionStorageKey).forEach(key => {
        sessionStorage.removeItem(key);
      });
    Object.values(BatchSessionStorageKey).forEach(key => {
        sessionStorage.removeItem(key);
    });
    Object.values(DeviceSessionStorageKey).forEach(key => {
        sessionStorage.removeItem(key);
    });
  };


  
export interface ColumnVisibilityState {
    [key: string]: boolean;
}

export interface FilterState {
    id: string;
    value: string;
}

export interface PaginationState {
    pageSize: number;
    pageIndex: number;
}

export enum ResultSessionStorageKey {
    sorting = 'result_sorting',
    columnVisibility = 'result_columnVisibility',
    pagination = 'result_pagination',
    columnFilters = 'result_columnFilters',
    search = 'result_search',
    showSearch = 'result_showSearch',
    data = 'results_data',
    count = 'results_count'
}

export enum UserSessionStorageKey {
    sorting = 'user_sorting',
    columnVisibility = 'user_columnVisibility',
    pagination = 'user_pagination',
    columnFilters = 'user_columnFilters',
    search = 'user_search',
    showSearch = 'user_showSearch',
    data = 'user_data',
    count = 'user_count'
}

export enum CycleSessionStorageKey {
    sorting = 'cycle_sorting',
    columnVisibility = 'cycle_columnVisibility',
    pagination = 'cycle_pagination',
    columnFilters = 'cycle_columnFilters',
    search = 'cycle_search',
    showSearch = 'cycle_showSearch',
    data = 'cycle_data',
    count = 'cycle_count'
}

export enum BatchSessionStorageKey {
    sorting = 'batch_sorting',
    columnVisibility = 'batch_columnVisibility',
    pagination = 'batch_pagination',
    columnFilters = 'batch_columnFilters',
    search = 'batch_search',
    showSearch = 'batch_showSearch',
    data = 'batch_data',
    count = 'batch_count'
}

export enum DeviceSessionStorageKey {
    sorting = 'device_sorting',
    columnVisibility = 'device_columnVisibility',
    pagination = 'device_pagination',
    columnFilters = 'device_columnFilters',
    search = 'device_search',
    showSearch = 'device_showSearch',
    data = 'device_data',
    count = 'device_count'
}

export interface ResultSessionStorageModel {
    [ResultSessionStorageKey.sorting]: any;
    [ResultSessionStorageKey.columnVisibility]: any;
    [ResultSessionStorageKey.pagination]: any;
    [ResultSessionStorageKey.columnFilters]: any;
    [ResultSessionStorageKey.search]: any;
    [ResultSessionStorageKey.showSearch]: any;
    [ResultSessionStorageKey.data]: any;
    [ResultSessionStorageKey.count]: any;
  }

export interface UserSessionStorageModel {
    [UserSessionStorageKey.sorting]: any;
    [UserSessionStorageKey.columnVisibility]: any;
    [UserSessionStorageKey.pagination]: any;
    [UserSessionStorageKey.columnFilters]: any;
    [UserSessionStorageKey.search]: any;
    [UserSessionStorageKey.showSearch]: any;
    [UserSessionStorageKey.data]: any;
    [UserSessionStorageKey.count]: any;
}

export interface CycleSessionStorageModel {
    [CycleSessionStorageKey.sorting]: any;
    [CycleSessionStorageKey.columnVisibility]: any;
    [CycleSessionStorageKey.pagination]: any;
    [CycleSessionStorageKey.columnFilters]: any;
    [CycleSessionStorageKey.search]: any;
    [CycleSessionStorageKey.showSearch]: any;
    [CycleSessionStorageKey.data]: any;
    [CycleSessionStorageKey.count]: any;
}

export interface BatchSessionStorageModel {
    [BatchSessionStorageKey.sorting]: any;
    [BatchSessionStorageKey.columnVisibility]: any;
    [BatchSessionStorageKey.pagination]: any;
    [BatchSessionStorageKey.columnFilters]: any;
    [BatchSessionStorageKey.search]: any;
    [BatchSessionStorageKey.showSearch]: any;
    [BatchSessionStorageKey.data]: any;
    [BatchSessionStorageKey.count]: any;
}

export interface DeviceSessionStorageModel {
    [DeviceSessionStorageKey.sorting]: any;
    [DeviceSessionStorageKey.columnVisibility]: any;
    [DeviceSessionStorageKey.pagination]: any;
    [DeviceSessionStorageKey.columnFilters]: any;
    [DeviceSessionStorageKey.search]: any;
    [DeviceSessionStorageKey.showSearch]: any;
    [DeviceSessionStorageKey.data]: any;
    [DeviceSessionStorageKey.count]: any;
}

export const ResultTableParameterStorageService = new StorageService<ResultSessionStorageModel>(
    sessionStorage
);

export const UserTableParameterStorageService = new StorageService<UserSessionStorageModel>(
    sessionStorage
);

export const CycleTableParameterStorageService = new StorageService<CycleSessionStorageModel>(
    sessionStorage
);

export const BatchTableParameterStorageService = new StorageService<BatchSessionStorageModel>(
    sessionStorage
);

export const DeviceTableParameterStorageService = new StorageService<DeviceSessionStorageModel>(
    sessionStorage
)
  