import React from "react";
import { Box, Card, useMediaQuery, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import colorConfigs from "../../../configs/colorConfigs";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PetsIcon from '@mui/icons-material/Pets';
import RotateRightOutlinedIcon from '@mui/icons-material/RotateRightOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

interface DashboardCreationTimelineTableProps {
    data: any[];
    timeframes: any[];
    OnCreationTimelineChange: (detail: any) => void;
}

const DashboardCreationTimelineTable: React.FC<DashboardCreationTimelineTableProps> = ({ data, timeframes, OnCreationTimelineChange }) => {
    
    const onChangeTimeframe = (time: any) => {
      console.log(time)
    }
    
    const settings = {
        dots: false, // Show dots for navigation
        infinite: true, // Loop the slides
        speed: 500, // Transition speed
        slidesToShow: 1, // Show one slide at a time
        slidesToScroll: 1, // Scroll one slide at a time
        arrows: true, // Show arrows
        afterChange: (current: number) => {
          console.log("Slider changed to slide:", current);
          OnCreationTimelineChange(current);
        },
        };

    const formatDate = (dateString: any) => {
      const date = new Date(dateString);
      const day = date.getDate();
      const daySuffix = (day: any) => {
          if (day > 3 && day < 21) return 'th';
          switch (day % 10) {
              case 1: return "st";
              case 2: return "nd";
              case 3: return "rd";
              default: return "th";
          }
      };
  
      const options: any = { day: 'numeric', month: 'long'};
      return `${day}${daySuffix(day)} ${date.toLocaleDateString('en-GB', options).slice(3)}`;
  };
  
  const formattedCategories = data.map(d => formatDate(d.day));
  
  console.log(formattedCategories);

  const CustomLegend = () => {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mb={3} gap="2%" sx={{paddingBootom: 2}}>
        <Box display="flex" alignItems="center" mr={2}>
          <PeopleAltOutlinedIcon fontSize="small" style={{ color: '#FF4560', marginRight: 4 }} />
          <Typography variant="body1" fontSize="smaller" sx={{ fontWeight: "bold" }}>User Added</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <PetsIcon fontSize="small" style={{ color: '#008FFB', marginRight: 4 }} />
          <Typography variant="body1" fontSize="smaller" sx={{ fontWeight: "bold" }}>Dogs Added</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <RotateRightOutlinedIcon fontSize="small" style={{ color: '#00E396', marginRight: 4 }} />
          <Typography variant="body1" fontSize="smaller" sx={{ fontWeight: "bold" }}>Cycles Started</Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <InsertChartOutlinedIcon fontSize="small" style={{ color: '#775DD0', marginRight: 4 }} />
          <Typography variant="body1" fontSize="smaller" sx={{ fontWeight: "bold" }}>Results Posted</Typography>
        </Box>
      </Box>
    );
  };


    // Prepare the data for the ApexCharts bar chart
    const chartData = {
      series: [
          {
              name: 'Users Added',
              data: data.map(d => d.users),
          },
          {
              name: 'Dogs Added',
              data: data.map(d => d.dogs),
          },
          {
              name: 'Cycles Started',
              data: data.map(d => d.cycles),
          },
          {
              name: 'Results Posted',
              data: data.map(d => d.results),
          },
      ],
      options: {
      //   title: {
      //     text: `Activity`,
      //     align: 'left',
      //     margin: 10,
      //     offsetX: 0,
      //     offsetY: 0,
      //     floating: false,
      //     style: {
      //         fontSize: '20px',
      //         fontWeight: 'bold',
      //         fontFamily: undefined,
      //         color: '#263238'
      //     },
      // },
      noData: {
        text: "No Data",
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
            color: "#000000",
            fontSize: '40px',
            fontFamily: "Helvetica"
        }
    },
        legend: {
          show: false,
          position: 'top',
      },

          chart: {
              type: 'bar',
              height: "100%",
          },
          plotOptions: {
              bar: {
                  horizontal: false,
                  columnWidth: '55%',
                  endingShape: 'rounded',
              },
          },
          dataLabels: {
              enabled: false,
          },
          stroke: {
              show: true,
              width: 2,
              colors: ['transparent'],
          },
          xaxis: {
            type: 'datetime',
            categories: data.map(d => d.day),
            // categories: formattedCategories
          },
          yaxis: {
              title: {
                  text: 'Number',
              },
          },
          fill: {
              opacity: 1,
          },
          tooltip: {
              y: {
                  formatter: function (val: number) {
                      return val.toString();
                  },
              },
          },
          colors: ['#FF4560', '#008FFB', '#00E396', '#775DD0'],
      } as ApexOptions,
  };



    return (
        <Box sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", background: colorConfigs.dashboard.border}}>
            
            <Box sx={{ width: "25%", margin: "0 auto",  paddingY: 0, paddingX: 4}}>
            <Slider {...settings}>
            {timeframes.map((timeframe: any) => (
                <div
                key={timeframe.id}
                style={{ cursor: "pointer"}}
                // onClick={() => onChangeTimeframe(timeframe.time)}
                >
                <Typography variant="body1" sx={{ marginBottom: 0.4, textAlign: "center", p: 1, fontWeight: "bold", color: "#fff"}}>
                    {timeframe.time}
                </Typography>
                </div>
            ))}
            </Slider>
            </Box>

        <Box  sx={{
                height: '100%',
                overflowY: 'auto',
                '&::-webkit-scrollbar': { display: 'none' },
                '-ms-overflow-style': 'none', // Internet Explorer 10+
                'scrollbar-width': 'none', // Firefox
              }}>

        <Box sx={{ paddingBottom:5, height: "100%", background: "#fff", marginX: "auto", borderTopRightRadius: 0,  borderTopLeftRadius: 0}}>
              {/* Apex chart with the Y axis being the number of objects created "Number", the X axis being individual days based on the date.  
              Each day should have a bar in the barchart with colors for users "red", dogs "blue", cycles "green", results "purple",  */}
              <Chart
                  options={chartData.options}
                  series={chartData.series}
                  type="bar"
                  height="83%"
                  
              />
              <CustomLegend />
        </Box>
    </Box>
    </Box>
    );
}

export default DashboardCreationTimelineTable;

