import React, { useEffect, useState } from "react";
import { Box, Card, useMediaQuery, Table, TableBody, TableCell, TableRow, Typography, Grid } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import colorConfigs from "../../configs/colorConfigs";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PetsIcon from '@mui/icons-material/Pets';
import RotateRightOutlinedIcon from '@mui/icons-material/RotateRightOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import CustomLegend from "./CustomLegend";

interface InterpretationsPieChartProps {
    resultClassifications: any;
    resultStates: any;
    timeframes: any[];
    OnResultClassificationChange: (detail: any) => void;
}

const InterpretationsPieChart: React.FC<InterpretationsPieChartProps> = ({ resultStates, resultClassifications, timeframes, OnResultClassificationChange }) => {
    
    const [stateTotal, setStateTotal] = useState<number>(0);
    const [statesChartSeries, setStatesChartSeries] = useState<number[]>([]);
    const [classificationTotal, setClassificationTotal] = useState<number>(0);
    const [classificationChartSeries, setClassificationChartSeries] = useState<number[]>([]);

    useEffect(() => {
        const statesSeries: number[] = Object.values(resultStates);
        const statesCalculatedTotal: number = statesSeries.reduce((sum, value) => sum + value, 0);
        setStateTotal(statesCalculatedTotal);
        setStatesChartSeries(statesSeries);

        const classificationSeries: number[] = Object.values(resultClassifications);
        const classificationCalculatedTotal: number = classificationSeries.reduce((sum, value) => sum + value, 0);
        console.log('Calculated series:', classificationSeries); // Debug log
        setClassificationTotal(classificationCalculatedTotal);
        setClassificationChartSeries(classificationSeries);

    }, [resultStates, resultClassifications]);
    
    const onChangeTimeframe = (time: any) => {
      console.log(time)
    }
    
    const settings = {
        dots: false, // Show dots for navigation
        infinite: true, // Loop the slides
        speed: 500, // Transition speed
        slidesToShow: 1, // Show one slide at a time
        slidesToScroll: 1, // Scroll one slide at a time
        arrows: true, // Show arrows
        afterChange: (current: number) => {
          console.log("Slider changed to slide:", current);
          OnResultClassificationChange(current);
        },
        };


    // const statesChartSeries: number[] = Object.values(resultClassifications);

    const statesChartOptions: ApexCharts.ApexOptions = {
            chart: {
                type: 'donut',
                offsetX: 0,
                offsetY: 0,
            },
            labels: ['Below', 'Valid', 'Above', 'Invalid'],
            colors: [
                colorConfigs.result.BELOW,
                colorConfigs.result.VALID,
                colorConfigs.result.ABOVE,
                colorConfigs.result.INVALID,
            ],
            fill: {
                type: 'gradient',
            },
            dataLabels: {
                enabled: false,
                style: {
                    colors: ['#000'], // Set the text color to black
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    // fontWeight: 'bold',
                },
                dropShadow: {
                    enabled: false, // Disable text shadow
                },
            },
            legend: {
                show: false,
                position: 'left',
            },
            tooltip: {
                enabled: false, // turned off tool tip
                y: {
                    formatter: function (val: number, opts: any): string {
                        try {
                            const seriesIndex = opts.seriesIndex;
                            const seriesName = opts.w.globals.seriesNames[seriesIndex];
                            const total = opts.w.globals.seriesTotals.reduce((a: number, b: number) => a + b, 0);
                            const percentage = ((val / total) * 100).toFixed(2) + '%';
                            return `${seriesName}: ${val} (${percentage})`;
                        } catch (error) {
                            console.error("Error formatting tooltip:", error);
                            return val.toString();
                        }
                    },
                },
                theme: 'light', 
            },
            plotOptions: {
                pie: {
                    donut: {
                        size: '50%', // Adjust the size of the donut hole (smaller value makes the donut smaller)
                        labels: {
                            show: true,
                            total: {
                                show: false,
                                label: 'Total',
                                formatter: () => `${stateTotal}`
                            }
                        }
                    }
                }
            },
        };

        const classificationChartOptions: ApexCharts.ApexOptions = {
            chart: {
                type: 'donut',
                offsetX: 0,
                offsetY: 0,
            },
            labels: ['Basline', 'Labour Imminent', 'Basline', 'Labour Imminent', 'LH Surge', 'Ovulation Likely', 'Progesterone Rise', 'High Progesterone', 'High Progesterone', "Invalid"],
            colors: [
                "#FFFF03",  // B Baseline (light yellow)
                "#FFD700",  // B Labour Imminent (main yellow)
                "#D0F066",  // V Baseline (light green)
                "#A7E531",  // V High Labour Imminent (main green)
                "#98D22B",  // V LH Surge (medium green)
                "#88BF25",  // V Ovulation Likely (darker green)
                "#79AC1F",  // V Progesterone Rise (darker green)
                "#6A991A",  // V High Progesterone (darkest green)
                "#FF4141",  // A High Progesterone (main red)
                "#a7a7b7"   // Invalid (grey)
            ],
            fill: {
                type: 'gradient',
            },
            dataLabels: {
                enabled: false,
                style: {
                    colors: ['#000'], // Set the text color to black
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    // fontWeight: 'bold',
                },
                dropShadow: {
                    enabled: false, // Disable text shadow
                },
            },
            legend: {
                show: false,
                position: 'right',

            },
            tooltip: {
                enabled: false, // turned off tool tip
                y: {
                    formatter: function (val: number, opts: any): string {
                        try {
                            const seriesIndex = opts.seriesIndex;
                            const seriesName = opts.w.globals.seriesNames[seriesIndex];
                            const total = opts.w.globals.seriesTotals.reduce((a: number, b: number) => a + b, 0);
                            const percentage = ((val / total) * 100).toFixed(2) + '%';
                            return `${seriesName}: ${val} (${percentage})`;
                        } catch (error) {
                            console.error("Error formatting tooltip:", error);
                            return val.toString();
                        }
                    },
                },
                theme: 'light', 
            },
            plotOptions: {
                pie: {
                    donut: {
                        size: '75%', // Adjust the size of the donut hole (smaller value makes the donut smaller)
                        labels: {
                            show: true,
                            total: {
                                show: false,
                                label: 'Total',
                                formatter: () => `${classificationTotal}`
                            }
                        }
                    }
                }
            },
        };

    return (
        <Box sx={{ height: "100%", display: "flex", flexDirection: "column", alignItems: "center", background: colorConfigs.dashboard.border }}>
            <Box sx={{ width: "85%", paddingY: 0, paddingX: 6, borderRadius: 0 }}>
                <Slider {...settings}>
                    {timeframes.map((timeframe: any) => (
                        <div key={timeframe.id} style={{ cursor: "pointer" }}>
                            <Typography variant="body1" sx={{ marginBottom: 0.4, textAlign: "center", p: 1, fontWeight: "bold", color: "#fff" }}>
                                {timeframe.time}
                            </Typography>
                        </div>
                    ))}
                </Slider>
            </Box>
            {/* <CustomLegend stateTotal={stateTotal} /> */}
            <Grid container direction="column" style={{ width: '100%', height: "100%" }}>
                <Grid item xs={12} sx={{ background: "#fff", width: "100%" }}>
                    <Box sx={{ height: "90%", width: "100%", display: "flex", position: "relative" }}>
                        <Box sx={{ height: "100%", width: "50%", display: 'flex', flexDirection: "column",justifyContent: 'center', alignItems: 'center' }}>
                            <Box sx={{ width: "100%", height: "100%", background: "#fff" }}>
                                <ReactApexChart options={statesChartOptions} series={statesChartSeries} type="donut" width="100%" height="100%" />
                            </Box>
                            <Typography variant="body1" fontWeight="bold" sx={{zIndex: 1, position: "absolute", bottom: 10, left: "4%", m:0, p:0}}>State</Typography>
                        </Box>
                        <Box sx={{ height: "100%", width: "50%", display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                            <Box sx={{ width: "100%", height: "100%", background: "#fff" }}>
                                <ReactApexChart options={classificationChartOptions} series={classificationChartSeries} type="donut" width="100%" height="100%" />
                            </Box>
                            <Typography variant="body1" fontWeight="bold" sx={{zIndex: 1, position: "absolute", bottom: 10, left: "54%", m:0, p:0}}>Classification</Typography>
                        </Box>
                        <Typography variant="body1" fontWeight="bold" sx={{ zIndex: 1, position: "absolute", top: 10, left: "50%", transform: "translateX(-50%)" }}>
                            Total Results: {stateTotal}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

        </Box>
    );
}

export default InterpretationsPieChart;

