import React from "react";
import { Box, Card, useMediaQuery, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import colorConfigs from "../../../configs/colorConfigs";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PetsIcon from '@mui/icons-material/Pets';
import RotateRightOutlinedIcon from '@mui/icons-material/RotateRightOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface DashboardSummaryTableProps {
    totals: any;
    timeframeSummary: any;
    timeframes: any[];
    OnTimeframeSummaryTimeframeChange: (detail: any) => void;
}

const DashboardSummaryTable: React.FC<DashboardSummaryTableProps> = ({ totals, timeframeSummary, timeframes, OnTimeframeSummaryTimeframeChange }) => {
    
    const onChangeTimeframe = (time: any) => {
      console.log(time)
    }
    
    const settings = {
        dots: false, // Show dots for navigation
        infinite: true, // Loop the slides
        speed: 500, // Transition speed
        slidesToShow: 1, // Show one slide at a time
        slidesToScroll: 1, // Scroll one slide at a time
        arrows: true, // Show arrows
        afterChange: (current: number) => {
          console.log("Slider changed to slide:", current);
          OnTimeframeSummaryTimeframeChange(current);
        },
        };

    return (
        <Box sx={{height: "100%",display: "flex", flexDirection: "column", justifyContent: "center", background: colorConfigs.dashboard.border}}>
            
            <Box sx={{ width: "85%", margin: "0 auto",  paddingY: 0, paddingX: 4}}>
            <Slider {...settings}>
            {timeframes.map((timeframe: any) => (
                <div
                key={timeframe.id}
                style={{ cursor: "pointer"}}
                // onClick={() => onChangeTimeframe(timeframe.time)}
                >
                <Typography variant="body1" sx={{ marginBottom: 0.4, textAlign: "center", p: 1, fontWeight: "bold", color: "#fff"}}>
                    {timeframe.time}
                </Typography>
                </div>
            ))}
            </Slider>
            </Box>

        <Box  sx={{
                height: '100%',
                overflowY: 'auto',
                '&::-webkit-scrollbar': { display: 'none' },
                '-ms-overflow-style': 'none', // Internet Explorer 10+
                'scrollbar-width': 'none', // Firefox
              }}>

        <Table sx={{maxHeight: "93%", minHeight: "93%", background: "#fff", marginX: "auto", borderTopRightRadius: 0,  borderTopLeftRadius: 0,}}>
          <TableBody sx={{ height: "100%"}}>

            <TableRow>
              <TableCell sx={{ paddingY: "0.5%", paddingTop: "0.5%", marginY: "0.5%", paddingLeft: "20px" }}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                   Activity
                </Typography>
              </TableCell>
              <TableCell sx={{ paddingY: "0.5%", paddingTop: "0.5%", marginY: "0.5%"}}>
              <Typography variant="body1" sx={{  }}>
                   
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
                <TableCell sx={{paddingY: "0.5%", marginY: "0.5%", paddingLeft: "30px",  paddingTop: "0.5%", borderTopRightRadius: 2,  borderTopLeftRadius: 2,}}>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px"}}>
                        <PeopleAltOutlinedIcon fontSize="small" sx={{color: colorConfigs.dashboard.slider, p: 0, m:0}}/>
                        <Typography variant="body1" fontSize={"100%"}>New Users</Typography>
                    </div>
              </TableCell>
              <TableCell sx={{ textAlign: "center", paddingY: "0.5%", marginY: "0.5%", paddingRight: "30px", paddingTop: "0.5%" }}>
                <Typography variant="body1" fontSize={"100%"}>{timeframeSummary.total_active_users}</Typography>
              </TableCell>
            </TableRow>

            <TableRow sx={{backgroundColor: 'rgba(134, 88, 155, 0.05)'}} >
              <TableCell sx={{paddingY: "0.5%", marginY: "0.5%", paddingLeft: "30px",  paddingTop: "0.5%", borderTopRightRadius: 2,  borderTopLeftRadius: 2,}}>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px"}}>
                        <PetsIcon fontSize="small" sx={{color: colorConfigs.dashboard.slider, p: 0, m:0}}/>
                        <Typography variant="body1" fontSize={"100%"} >New Dogs</Typography>
                    </div>
              </TableCell>
              <TableCell sx={{ textAlign: "center", paddingY: "0.5%", marginY: "0.5%", paddingRight: "30px", paddingTop: "0.5%" }}>
                <Typography variant="body1" fontSize={"100%"}>{timeframeSummary.total_active_dogs}</Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{paddingY: "0.5%", marginY: "0.5%", paddingLeft: "30px",  paddingTop: "0.5%", borderTopRightRadius: 2,  borderTopLeftRadius: 2,}}>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px"}}>
                        <RotateRightOutlinedIcon fontSize="small" sx={{color: colorConfigs.dashboard.slider, p: 0, m:0}}/>
                        <Typography variant="body1" fontSize={"100%"}>New Cycles</Typography>
                    </div>
              </TableCell>
              <TableCell sx={{ textAlign: "center", paddingY: "0.5%", marginY: "0.5%", paddingRight: "30px", paddingTop: "0.5%" }}>
                <Typography variant="body1" fontSize={"100%"}>{timeframeSummary.total_active_cycles}</Typography>
              </TableCell>
            </TableRow>

            <TableRow sx={{backgroundColor: 'rgba(134, 88, 155, 0.05)'}}>
              <TableCell sx={{paddingY: "0.5%", marginY: "0.5%", paddingLeft: "30px",  paddingTop: "0.5%", borderTopRightRadius: 2,  borderTopLeftRadius: 2,}}>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px"}}>
                        <InsertChartOutlinedIcon fontSize="small" sx={{color: colorConfigs.dashboard.slider, p: 0, m:0}}/>
                        <Typography variant="body1" fontSize={"100%"}>New Results</Typography>
                    </div>
              </TableCell>
              <TableCell sx={{ textAlign: "center", paddingY: "0.5%", marginY: "0.5%", paddingRight: "30px", paddingTop: "0.5%" }}>
                <Typography variant="body1" fontSize={"100%"}>{timeframeSummary.total_results}</Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{ paddingY: "0.5%", paddingTop: "0.5%", marginY: "0.5%", paddingLeft: "20px" }}>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                   Totals
                </Typography>
              </TableCell>
              <TableCell sx={{ paddingY: "0.5%", paddingTop: "0.5%", marginY: "0.5%"}}>
              <Typography variant="body1"sx={{  }}>
                   
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{paddingY: "0.5%", marginY: "0.5%", paddingLeft: "30px",  paddingTop: "0.5%", borderTopRightRadius: 2,  borderTopLeftRadius: 2,}}>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px"}}>
                        <PeopleAltOutlinedIcon fontSize="small" sx={{color: colorConfigs.dashboard.slider, p: 0, m:0}}/>
                        <Typography variant="body1" fontSize={"100%"}>Active Users</Typography>
                    </div>
              </TableCell>
              <TableCell sx={{ textAlign: "center", paddingY: "0.5%", marginY: "0.5%", paddingRight: "30px", paddingTop: "0.5%" }}>
                <Typography variant="body1" fontSize={"100%"}>{totals.total_active_users}</Typography>
              </TableCell>
            </TableRow>

            <TableRow sx={{backgroundColor: 'rgba(134, 88, 155, 0.05)'}}>
              <TableCell sx={{paddingY: "0.5%", marginY: "0.5%", paddingLeft: "30px",  paddingTop: "0.5%", borderTopRightRadius: 2,  borderTopLeftRadius: 2,}}>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px"}}>
                        <RotateRightOutlinedIcon fontSize="small" sx={{color: colorConfigs.dashboard.slider, p: 0, m:0}}/>
                        <Typography variant="body1" fontSize={"100%"}>Active Cycles</Typography>
                    </div>
              </TableCell>
              <TableCell sx={{ textAlign: "center", paddingY: "0.5%", marginY: "0.5%", paddingRight: "30px", paddingTop: "0.5%" }}>
                <Typography variant="body1" fontSize={"100%"}>{totals.total_active_cycles}</Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={{paddingY: "0.5%", marginY: "0.5%", paddingLeft: "30px",  paddingTop: "0.5%", borderTopRightRadius: 2,  borderTopLeftRadius: 2,}}>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px"}}>
                        <PetsIcon fontSize="small" sx={{color: colorConfigs.dashboard.slider, p: 0, m:0}}/>
                        <Typography variant="body1" fontSize={"100%"}>Active Dogs</Typography>
                    </div>
              </TableCell>
              <TableCell sx={{ textAlign: "center", paddingY: "0.5%", marginY: "0.5%", paddingRight: "30px", paddingTop: "0.5%" }}>
                <Typography variant="body1" fontSize={"100%"}>{totals.total_active_dogs}</Typography>
              </TableCell>
            </TableRow>

            <TableRow sx={{backgroundColor: 'rgba(134, 88, 155, 0.05)'}}>
              <TableCell sx={{paddingY: "0.5%", marginY: "0.5%", paddingLeft: "30px",  paddingTop: "0.5%", borderTopRightRadius: 2,  borderTopLeftRadius: 2, paddingBottom: "3%"}}>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px"}}>
                        <PetsIcon fontSize="small" sx={{color: colorConfigs.dashboard.slider, p: 0, m:0}}/>
                        <Typography variant="body1" fontSize={"100%"}>Memorialised Dogs</Typography>
                    </div>
              </TableCell>
              <TableCell sx={{ textAlign: "center", paddingY: "0.5%", marginY: "0.5%", paddingRight: "30px", paddingTop: "0.5%", paddingBottom: "3%"  }}>
                <Typography variant="body1" fontSize={"100%"}>{totals.total_memorialised_dogs}</Typography>
              </TableCell>
            </TableRow>

          </TableBody>
        </Table>
    </Box>
    </Box>
    );
}

export default DashboardSummaryTable;

