import { Card, Container, createTheme, ThemeProvider } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import Chart from "react-apexcharts";
import dayjs, { Dayjs } from 'dayjs';
import { useNavigate, useLocation } from "react-router-dom";
import { lightGreen } from '@mui/material/colors';
import colorConfigs from '../../../configs/colorConfigs';


interface LineData {
  score: number | string;
  peak_position: number | string;
  tc_ratio: number | string;
}

interface GraphData {
  test_lines: LineData[];
  cscore: number | string;
  cpeak: number | string;
  profile: string[];
  baseline: string[];
  quantitativeresult: number | string;
  thesholdmin: number | string;
  thesholdmax: number | string;
}

const ResultDetailGraph: React.FC<{ graphdata: GraphData }> = ({ graphdata }) => {
  const [apexChartData, setApexChartData] = useState<any[]>([]);
  const [apexChartData2, setApexChartData2] = useState<any[]>([]);
  const [graphText, setGraphText] = useState<any>("Loading...")

  const [tpeaks, setTpeaks] = useState<any>("")
  const [cpeak, setCpeak] = useState<any>("")

  useEffect(() => {
    const baseline_data = graphdata?.baseline?.map((value: any, i:any) => ({ x: i, y: parseInt(value, 10) }));
    const profile_data = graphdata?.profile?.map((value:any, i:any) => ({ x: i, y: parseInt(value, 10) }));
    setCpeak(graphdata.cpeak)
    setApexChartData(baseline_data)
    setApexChartData2(profile_data)

    const lineTPeaks = graphdata.test_lines.map((line, index) => {
      return {
              x: line.peak_position,
              borderColor: colorConfigs.result.tpeak,
              label: {
                borderColor: colorConfigs.result.tpeak,
                orientation: 'vertical',
                style: {
                  color: '#ffffff',
                  fontWeight: "bold",
                  textSize: "10px",
                  background: colorConfigs.result.tpeak
                },
                text: `TPeak ${line.peak_position}`
              }
            }
    })
    setTpeaks(lineTPeaks)
  
  }, [graphdata]);



  const chartData: ApexOptions = {
    noData: {
      text: graphText,
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        // color: "#ffffff",
        fontSize: '40px',
        // fontFamily: "Helvetica"
      }
    },
    tooltip: {
      enabled: true, // Can Disable tooltip when hovering over the graph
    },
    annotations: {
      yaxis: [
      ],
      xaxis: [
        {
          x: cpeak,
          borderColor: colorConfigs.result.cpeak,
          label: {
            borderColor: colorConfigs.result.cpeak,
            orientation: 'vertical',
            style: {
              color: '#ffffff',
              fontWeight: "bold",
              textSize: "10px",
              background: colorConfigs.result.cpeak
            },
            text: `CPeak ${cpeak}`
          }
        },
        ...tpeaks
      ],
      
    },
    colors: ['#ff6347', '#1e90ff'],
    stroke: {
      width: 2,
      curve: 'smooth',
    },
    chart: {
      id: "basic-bar",
      type: "area",
      // background: '#1e1e1e',  // Dark background color
      // foreColor: '#ffffff',  // Light color for text
      toolbar: {
        show: true,
        tools: {
          download: true,
        },
      },
    },
    xaxis: {
      type: 'numeric',
      title: {
        text: 'Profile',
        style: {
        }
      },
      labels: {
        style: {
        }
      }
    },
    yaxis: {
      // type: 'numeric'
      // max: 250

      title: {
        text: 'Intensity',
        style: {
        }
      },
      labels: {
        style: {
        }
      }
    },
    grid: {
      // borderColor: '#1D1D1D'
    },
    series: [
      {
        name: "Baseline",
        data: apexChartData,
        color: colorConfigs.result.baseline
      },
      {
        name: "Profile",
        data: apexChartData2,
        color: colorConfigs.result.profile
      }
    ]
  }

  return (apexChartData.length > 1 && apexChartData2.length > 1 ) ? (
    <div className="col-md-12" style={{ marginLeft: "auto", marginRight: "auto" }}>
      <Card sx={{ p: 0, m:0, paddingTop: 1, marginY: 1, display: 'flex', justifyContent: "center", alignItems: "center",width: "520px", height: "240px", boxShadow: 2 }} >
      <ThemeProvider theme={createTheme()}>
          <Container component="main" maxWidth="xl" sx={{display: 'flex', justifyContent: "center", alignItems: "center",}}>
            <ReactApexChart height="220px" width="520px" type="line" options={chartData} series={chartData.series} />
          </Container>
        </ThemeProvider>
      </Card>
    </div>
  ) : (null)

}

export default ResultDetailGraph;
