import { AppBar, Divider, Grid, IconButton, Menu, MenuItem, styled, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import ProfileDropdown from "./buttons/profileDropdown";
import React, { useEffect, useState } from "react";

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);
const topBarWidth = 100% - sizeConfigs.sidebar.halfwidth

const Topbar = () => {
  const [isProfileHidden, setProfileHidden] = useState(false);
  const [isProfileFading, setProfileFading] = useState(false);

  useEffect(() => { // Hides profile Icon on scroll
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setProfileFading(true);
      } else {
        setProfileFading(false);
      }
      if (window.scrollY > 120) {
        setProfileHidden(true);
      } else {
        setProfileHidden(false);
        
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toolbarStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    opacity: 0, // Initially set opacity to 0 when hidden
    transition: 'opacity 0.3s ease-in-out', // Add opacity transition
  };
  

  return (
    <AppBar
      position="fixed"
      sx={{
        width: topBarWidth,
        ml: sizeConfigs.sidebar.halfwidth,
        boxShadow: "unset",
        backgroundColor: 'transparent',
        color: colorConfigs.topbar.color,
      }}
    >
      {!isProfileHidden && 
        <Toolbar sx={!isProfileFading ? { ...toolbarStyle, opacity: 0.96 } : toolbarStyle}>
          <ProfileDropdown/>
        </Toolbar>
        }
    </AppBar>
    
  );
};

export default Topbar;