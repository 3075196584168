import React from 'react';
import ResetPasswordPage from '../../pages/auth/ResetPasswordPage';

type Props = {}

const ResetPasswordLayout = (props: Props) => {
  return (
    <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
      <ResetPasswordPage />
    </div>
  )
}

export default ResetPasswordLayout