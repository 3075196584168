import React from "react";
import { Box, Card, useMediaQuery, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import colorConfigs from "../../../configs/colorConfigs";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PetsIcon from '@mui/icons-material/Pets';
import RotateRightOutlinedIcon from '@mui/icons-material/RotateRightOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface Top10UsersTableProps {
    top10Users: any[];
    timeframes: any[];
    OnTop10UserTimeframeChange: (detail: any) => void;
}

const Top10UsersTable: React.FC<Top10UsersTableProps> = ({ top10Users, timeframes, OnTop10UserTimeframeChange }) => {
    
    const onChangeTimeframe = (time: any) => {
      console.log(time)
    }
    
    const settings = {
        dots: false, // Show dots for navigation
        infinite: true, // Loop the slides
        speed: 500, // Transition speed
        slidesToShow: 1, // Show one slide at a time
        slidesToScroll: 1, // Scroll one slide at a time
        arrows: true, // Show arrows
        afterChange: (current: number) => {
          console.log("Slider changed to slide:", current);
          OnTop10UserTimeframeChange(current);
        },
        };

    return (
      <Box sx={{height: "100%",display: "flex", flexDirection: "column", justifyContent: "center", background: colorConfigs.dashboard.border}}>
            <Box sx={{ width: "85%", margin: "0 auto",  paddingY: 0, paddingX: 4}}>
            <Slider {...settings}>
            {timeframes.map((timeframe: any) => (
                <div
                key={timeframe.id}
                style={{ cursor: "pointer"}}
                // onClick={() => onChangeTimeframe(timeframe.time)}
                >
                <Typography variant="body1" sx={{ marginBottom: 0.4, textAlign: "center", p: 1, fontWeight: "bold", color: "#fff"}}>
                    {timeframe.time}
                </Typography>
                </div>
            ))}
            </Slider>
            </Box>

        <Box sx={{
          height: "100%", 
          overflowY: 'auto',
          '&::-webkit-scrollbar': { display: 'none' },
          '-ms-overflow-style': 'none', // Internet Explorer 10+
          'scrollbar-width': 'none', // Firefox
          }}>

        <Table sx={{maxHeight: "93%", minHeight: "93%",  background: "#fff", marginX: "auto", borderTopRightRadius: 0,  borderTopLeftRadius: 0,}}>
          <TableBody sx={{ height: "100%", m: "auto"}}>
              <TableRow>
                  <TableCell sx={{paddingY: "0.5%", paddingTop: "0.5%", marginY: "0.5%",}}>
                      <div style={{ display: "flex", alignItems: "center", gap: "10px"}}>
                          <PeopleAltOutlinedIcon fontSize="medium" sx={{color: colorConfigs.dashboard.slider, p: 0, m:0}}/>
                          <Typography variant="body1" sx={{ fontWeight: "bold" }}>ID</Typography>
                      </div>
                </TableCell>
                <TableCell sx={{ textAlign: "center", paddingY: "0.5%", paddingTop: "0.5%", marginY: "0.5%",}}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>Name</Typography>
                </TableCell>
                <TableCell sx={{ textAlign: "center", paddingY: "0.5%", paddingTop: "0.5%", marginY: "0.5%", }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>Tests</Typography>
                </TableCell>
              </TableRow>

              {top10Users.map((user, index) => {
              const isLastRow = index === top10Users.length - 1;
              return (
                <TableRow key={index} sx={{ backgroundColor: index % 2 === 1 ? 'rgba(134, 88, 155, 0.05)' : 'inherit'}}>
                  <TableCell sx={{ paddingY: "0.5%", paddingLeft: "5%", marginY: "0.5%", paddingTop: "0.5%", paddingBottom: isLastRow ? "3%" : "0.5%" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px"}}>
                      <PeopleAltOutlinedIcon fontSize="small" sx={{color: colorConfigs.dashboard.slider, p: 0, m:0}}/>
                      <Typography variant="body1" fontSize={"100%"}>{user.id}</Typography>
                    </div>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", paddingY: "0.5%", marginY: "0.5%", paddingTop: "0.5%", paddingBottom: isLastRow ? "3%" : "0.5%" }}>
                    <Typography variant="body1" fontSize={"100%"}>{user.first_name} {user.last_name}</Typography>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", paddingY: "0.5%", marginY: "0.5%", paddingTop: "0.5%", paddingBottom: isLastRow ? "3%" : "0.5%" }}>
                    <Typography variant="body1" fontSize={"100%"}>{user.results_count}</Typography>
                  </TableCell>
                </TableRow>
              );
            })}


          </TableBody>
        </Table>
    </Box>
    </Box>
    );
}

export default Top10UsersTable;

