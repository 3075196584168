import { AppBar, Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Fade, Grid, IconButton, Link, List, ListItem, ListItemText, Paper, Slide, Toolbar, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { isLoggedIn } from '../../redux/features/auth/authService';
import SignInDialog from '../../components/common/SignInDialog';
import axios from 'axios';
import { logout, refreshAccessToken } from '../../redux/features/auth/authSlice';
import { CycleSessionStorageKey, CycleTableParameterStorageService } from '../../services/sessionStorage';
import CycleDetailCard from '../../components/common/cards/CycleDetailCard';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import CyclesList from '../../components/common/tables/CyclesList';
import colorConfigs from '../../configs/colorConfigs';
import { useLocation } from 'react-router-dom';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import RotateRightOutlinedIcon from '@mui/icons-material/RotateRightOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const loginpage = "/login"

axios.defaults.baseURL = window.env.API_URL;



type Props = {}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CyclesPage = (props: Props) => {

const navigate = useNavigate()
const authData = useSelector((state: RootState) => state.auth);
const [isLoading, setIsLoading] = useState<boolean>(false);
const [cycle, setCycle] = useState<any>(null);
const [popup, setPopup] = useState<any>("");
const [needToRefreshData, setNeedToRefreshData] = useState<any>(false);

const [sorting, setSorting] = useState<any>(() => {
  return CycleTableParameterStorageService.get(CycleSessionStorageKey.sorting) || []
});

const [columnVisibility, setColumnVisibility] = useState<any>(() => {
  return CycleTableParameterStorageService.get(CycleSessionStorageKey.columnVisibility) || {}
});

const [columnFilters, setColumnFilters] = useState<any>(() => {
  return CycleTableParameterStorageService.get(CycleSessionStorageKey.columnFilters) || []
});

const [globalFilter, setGlobalFilter] = useState<string>(() => {
  return CycleTableParameterStorageService.get(CycleSessionStorageKey.search) || "";
});

const [pagination, setPagination] = useState<any>(() => {
  return CycleTableParameterStorageService.get(CycleSessionStorageKey.pagination) || { pageSize: 1000, pageIndex: 0 }
});

const [showGlobalFilter, setShowGlobalFilter] = useState<any>(() => {
  return CycleTableParameterStorageService.get(CycleSessionStorageKey.showSearch) || false
});

const [data, setData] = useState<any>(() => {
  return CycleTableParameterStorageService.get(CycleSessionStorageKey.data) || []
});

const [count, setCount] = useState<any>(() => {
  return CycleTableParameterStorageService.get(CycleSessionStorageKey.count) || ""
});

const handleSaveButton = () => {
  setCycle("")
  fetchData()
  setPopup("")
}

const handleBackButton = () => {
  setCycle("")
  setPopup("")
}

useEffect(() => {
  if (data.length < 1) {
    fetchData();
  } else {
    if (!needToRefreshData) {
      checkForNewData();
    }
  }
  setNeedToRefreshData(false)
}, [data.length]); 

const query = useQuery();
const user_id: any = query.get("user_id")


const fetchData = () => {
  setIsLoading(true);
  let url = `api/resultcycles/`
  if (user_id){
    url = `api/resultcycles/?user_id=${user_id}`
  }
  console.log("url",url)
  axios.get(url, { 
    headers: {
    'Authorization': `Bearer ${authData.access}`
  }
})
  .then(res => {
    // Set Data
    setData(res.data.results);
    console.log(res.data.results)
    CycleTableParameterStorageService.set(CycleSessionStorageKey.data, res.data.results)
    CycleTableParameterStorageService.set(CycleSessionStorageKey.count, res.data.count)
    setIsLoading(false);
    setNeedToRefreshData(false)
  })
  .catch(err => {
    console.log(err)
  })
}

const checkForNewData = () => { 
  // If new data has been added then alert refresh data button
  axios.get(`api/resultcycles?limit=1`, { 
    headers: {
    'Authorization': `Bearer ${authData.access}`
  }
})
  .then(res => {
    // Set Data
    if (count && count != res.data.count) {
      console.log("count", count, res.data.count)
      // setNeedToRefreshData(true)
      fetchData()
    }
  })
  .catch(err => {
    console.log(err)
  })
}
const handleRemoveAllFilters = () => {
  setGlobalFilter("")
  setShowGlobalFilter(false)
  setColumnFilters([])
  setColumnVisibility({})
  setPagination({ pageSize: 1000, pageIndex: 0 })
  setSorting([])
}

// AUTHENTICATION CHECK
const dispatch = useDispatch<AppDispatch>();
useEffect(() => {
  const checkAuth = async () => {
      if (!authData.isAuthenticated) {
          dispatch(logout());
          navigate('/login');
          return;
      }
      if (!isLoggedIn(authData.access)) {
          await dispatch(refreshAccessToken());
      }
  };
  
  checkAuth();
}, [dispatch, navigate, authData.isAuthenticated, authData.access]);


  return authData.isAuthenticated === false ? (
    <SignInDialog isAuthenticated={authData.isAuthenticated} />
    ) : authData.isAuthenticated ? (
      <div style={{ overflowX: "hidden", maxWidth: `85vw`, minWidth: `85vw`, margin: "auto" }}>
      <div style={{ width: "100%", display: "flex", justifyContent: "space-between"}}>
      <Typography variant="h5" sx={{
            p:0, 
            m:0,
            mb: 1,
                              fontSize: ['30px', '40px', '50px'],
                  '@media (max-width: 1200px)': {
                    fontSize: '40px',
                  },
                  '@media (max-width: 800px)': {
                    fontSize: '30px',
                  },
            fontStretch: "normal",
            fontWeight: "bold",
            lineHeight: "normal",
            letterSpacing: "normal",
            color: colorConfigs.sidebar.color
          }}>
          {user_id ? 
          <div>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title="Return to users list"
            >
              <Link
                component="button" 
                color = {colorConfigs.sidebar.color}
                sx={{
                  textDecoration: "none",
                  fontSize: ['30px', '40px', '50px'],
                  '@media (max-width: 1200px)': {
                    fontSize: '40px',
                  },
                  '@media (max-width: 800px)': {
                    fontSize: '30px',
                  },
                }}
                onClick={() => {
                  navigate("/users")
                }}>
                <PeopleAltOutlinedIcon sx={{
                  p:0, 
                  mb:-1,
                  mr:1,
                  fontSize: ['30px', '40px', '50px'],
                  '@media (max-width: 1200px)': {
                    fontSize: '40px',
                  },
                  '@media (max-width: 800px)': {
                    fontSize: '30px',
                  },
                  fontStretch: "normal",
                  fontWeight: "bold",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  color: colorConfigs.sidebar.color,
                }}/>
                User <span style={{color: colorConfigs.sidebar.color, marginRight: "15px"}}>{`${user_id}`}</span>
              </Link> 
            </Tooltip>
            <ChevronRightIcon sx={{
                  p:0, 
                  mb:-1.5,
                  mr:1,
                  fontSize: ['30px', '40px', '50px'],
                  '@media (max-width: 1200px)': {
                    fontSize: '40px',
                  },
                  '@media (max-width: 800px)': {
                    fontSize: '30px',
                  },
                  fontStretch: "normal",
                  fontWeight: "bold",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  color: colorConfigs.sidebar.activeBg,
                }}/>
            <Link 
              component="button" 
              color = {colorConfigs.sidebar.color}
              sx={{
                textDecoration: "none",
                fontSize: ['30px', '40px', '50px'],
                '@media (max-width: 1200px)': {
                  fontSize: '40px',
                },
                '@media (max-width: 800px)': {
                  fontSize: '30px',
                },
                }} 
              onClick={() => {
                navigate(`/cycles?user_id=${user_id}`)
              }}>
                <RotateRightOutlinedIcon fontSize="large" sx={{
                  p:0, 
                  mb:-1,
                  mr:1,
                  fontSize: ['30px', '40px', '50px'],
                  '@media (max-width: 1200px)': {
                    fontSize: '40px',
                  },
                  '@media (max-width: 800px)': {
                    fontSize: '30px',
                  },
                  fontStretch: "normal",
                  fontWeight: "bold",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  color: colorConfigs.sidebar.color,
                }}/>
                Cycles
            </Link>
          </div>
          : `Cycles` }
      </Typography> 
    </div> 
    <div style={{ display: "flex", height: "100%", width: "100%" }}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{m:0}}
        >
          <Grid item xs={3} sx={{m:0, p:0}}>
            <Box sx={{ m:0, p:2, height: "100%", overflowY: "auto", marginRight: 6, display: 'flex', flexDirection: 'column', overflowX: "hidden", maxWidth: `85vw`, minWidth: `85vw` }}>
                <Box sx={{flex: 1, display: 'flex', flexDirection: 'column', overflowY: 'hidden', boxShadow: 2 }}>
                  <CyclesList
                    data={data}
                    isLoading={isLoading}
                    sorting={sorting}
                    columnVisibility={columnVisibility}
                    columnFilters={columnFilters}
                    pagination={pagination}
                    globalFilter={globalFilter}
                    showGlobalFilter= {showGlobalFilter}
                    setShowGlobalFilter={setShowGlobalFilter}
                    handleRemoveAllFilters={handleRemoveAllFilters}
                    setGlobalFilter={setGlobalFilter}
                    setSorting={setSorting}
                    setColumnVisibility={setColumnVisibility}
                    setColumnFilters={setColumnFilters}
                    setPagination={setPagination}
                    setCycle={setCycle}
                    fetchData={fetchData}
                    setPopup={setPopup}
                    needToRefreshData={needToRefreshData}
                  />
            </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
      <Dialog
            open={(cycle && popup === "Edit") || popup === "Create"}
            fullScreen
            onClose={() => {setCycle(""); setPopup("")}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Transition}
            sx={{
              '& .MuiDialog-paper': {
                alignItems: 'center',
                justifyContent: 'center',
              },
            }}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p:1, backgroundColor: colorConfigs.tables.headBg, opacity: 0.95}}>
                {(popup && cycle) &&
                <Typography
                      onClick={() => {setCycle(""); setPopup("")}}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "20px",
                        fontWeight: "bold",
                        m: "auto",
                        mt: 0,
                        mb: 0,
                        width: "40%",
                        borderRadius: 1,
                        padding: 1,
                        transition: "width 0.3s ease-in-out"
                      }}
                    >
                     {`Cycle ${cycle.id}`}
                    </Typography>  
                }
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {setCycle(""); setPopup("")}}
                  aria-label="close"
                  sx={{ position: 'absolute', right: 15 }}
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <DialogContent sx={{p:0, minWidth: "100vw", maxWidth: "100vw", backgroundColor: colorConfigs.sidebar.bg}}>
                {(popup) &&
                <CycleDetailCard cycle={cycle} create={popup === "Create"} handleSaveButton={handleSaveButton} handleBackButton={handleBackButton}  />
                }
            </DialogContent>
        </Dialog>
    </div>
    
) : (null);
};

export default CyclesPage;
