import { useState } from "react";
import { MaterialReactTable, MRT_Row, type MRT_ColumnDef, type MRT_SortingState } from 'material-react-table';
import dayjs from 'dayjs';
import { Box, Button, Grid, Link, ListItemIcon, Menu, MenuItem, MenuProps, Paper, Tooltip, Typography, alpha, styled } from '@mui/material';
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ResultSessionStorageKey, ResultSessionStorageModel, ResultTableParameterStorageService } from "../../../services/sessionStorage";
import RefreshIcon from '@mui/icons-material/Refresh';
import colorConfigs from "../../../configs/colorConfigs";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { tsXLXS } from 'ts-xlsx-export';
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

type InterpretationState = 'ABOVE' | 'VALID' | 'BELOW' | 'INVALID'

const StyledMenu = styled((props: any) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


interface ResultsListProps {
    data: any[];
    isLoading: boolean;
    sorting: any;
    columnVisibility: any;
    columnFilters: any;
    pagination: any;
    globalFilter: any;
    showGlobalFilter: any;
    needToRefreshData: boolean;
    setShowGlobalFilter: (value: any) => void;
    handleRemoveAllFilters: () => void;
    setGlobalFilter: (value: string) => void;
    setSorting: (sorting: any[]) => void;
    setColumnVisibility: (columnVisibility: { [key: string]: boolean }) => void;
    setColumnFilters: (filters: any[]) => void;
    setPagination: (pagination: { pageSize: number; pageIndex: number }) => void;
    setResult: (detail: any) => void;
    setPopup: (detail: any) => void;
    fetchData: () => void;
  }

const ResultsList: React.FC<ResultsListProps> = ({
  data,
  isLoading,
  sorting,
  columnVisibility,
  columnFilters,
  pagination,
  globalFilter,
  showGlobalFilter,
  needToRefreshData,
  setShowGlobalFilter,
  setGlobalFilter,
  handleRemoveAllFilters,
  setSorting,
  setColumnVisibility,
  setColumnFilters,
  setPagination,
  setResult,
  setPopup,
  fetchData
}) => {
    const authData = useSelector((state: RootState) => state.auth);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleSaveFilterClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    console.log("Results", data)


    // Helper function to get the display color
    const getColor = (state: InterpretationState) => colorConfigs.result[state] || colorConfigs.result.INVALID;
    // Helper function to get the display text based on interpretation state and value
    const getDisplayText = (interpretationState: any, interpretationValue: any) => {
      if (!interpretationValue) return interpretationState;
      switch (interpretationState) {
          case "BELOW":
              return `< ${interpretationValue}`;
          case "ABOVE":
              return `> ${interpretationValue}`;
          case "VALID":
              return interpretationValue;
          default:
              return interpretationState;
      }
    };

    const filterByValue = (row: any, columnId:any, filterValue:any) => {
      const interpretationValue = row.original.interpretations?.[0]?.interpretation_value || '';
      return interpretationValue.toString().toLowerCase().includes(filterValue.toLowerCase());
    };

    const addSpaceToCamelCase = (text: any) => {
      if (text){
        return text.replace(/([a-z])([A-Z])/g, '$1 $2');
      }
    };

    const filterByClassification = (row: any, columnId:any, filterValue:any) => {
      const interpretationClassification = row.original.interpretations?.[0]?.interpretation_classification;
      const formattedClassification = addSpaceToCamelCase(interpretationClassification) || '';
      return formattedClassification.toString().toLowerCase().includes(filterValue.toLowerCase());
    };

    const columns: MRT_ColumnDef<any>[] = [
        {
            accessorKey: 'id',
            header: 'ID',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            ID<br />&nbsp;
            </div>, 
        },
        {
            accessorFn: (row) => dayjs(row.timestamp).format('YYYY-MM-DD HH:mm:ss'),
            header: 'Timestamp',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            Timestamp<br />&nbsp;
            </div>, 
            Cell: ({ cell }) => (
              <div style={{ whiteSpace: 'pre-wrap' }}>
                {cell.getValue()?.toString().split(' ').join('\n')}
              </div>
            ),
        },
        {
            accessorKey: 'test_type',
            header: 'Test Type',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            Test<br />Type
            </div>, 
        },
        {
            accessorKey: 'created_by',
            header: 'User ID',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            User ID<br />&nbsp;
            </div>, 
            Cell: ({ row }) => {
              const userActive = row.original.user_is_active;
              const user = row.original.created_by;
              return (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                  }}
                >
                  {user}
                  {userActive && <Typography fontSize="smaller" sx={{ paddingX:0.7, border: `1.5px solid ${colorConfigs.buttons.active}`, borderRadius: 2, color: colorConfigs.buttons.active }} >Active</Typography>}
                </Box>
              );
          },
      },
        {
            accessorKey: 'dog',
            header: 'Dog ID',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            Dog ID<br />&nbsp;
            </div>, 
              Cell: ({ row }) => {
                const dogActive = row.original.dog_is_active;
                const dog = row.original.dog;
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.5rem',
                    }}
                  >
                    {dog}
                    {dogActive && <Typography fontSize="smaller" sx={{ paddingX:0.7, border: `1.5px solid ${colorConfigs.buttons.active}`, borderRadius: 2, color: colorConfigs.buttons.active }} >Active</Typography>}
                  </Box>
                );
            },
        },
        {
            accessorKey: 'cycle',
            header: 'Cycle ID',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            Cycle ID<br />&nbsp;
            </div>, 
            Cell: ({ row }) => {
              const cycleStatus = row.original.cycle_status;
              const cycle = row.original.cycle;
              return (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                  }}
                >
                  {cycle}
                  {cycleStatus && <Typography fontSize="smaller" sx={{ paddingX:0.7, border: `1.5px solid ${colorConfigs.buttons.active}`, borderRadius: 2, color: colorConfigs.buttons.active }} >Active</Typography>}
                </Box>
              );
            },
          },
        {
            accessorKey: 'lot',
            header: 'Batch ID',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            Batch ID<br />&nbsp;
            </div>, 
            Cell: ({ row }) => {
                const lotStatus = row.original.lot_is_active;
                const lot = row.original.lot;
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.5rem',
                    }}
                  >
                    {lot}
                    {lotStatus ? <Typography fontSize="smaller" sx={{ paddingX:0.7, border: `1.5px solid ${colorConfigs.buttons.active}`, borderRadius: 2, color: colorConfigs.buttons.active }} >Active</Typography> : <CheckCircleOutlineOutlinedIcon fontSize="small" sx={{ color: colorConfigs.result.red }} /> }
                  </Box>
                );
              },
        },
        {
            accessorKey: 'lot_expiry_date',
            header: 'Batch Expiry Date',
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            Batch<br />Expiry Date
            </div>, 
        },
        {
            accessorKey: 'interpretations.interpretation_value',
            header: 'Value',
            id: "value",
            filterFn: filterByValue,
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            Value<br />&nbsp;
            </div>, 
            Cell: ({ row }) => {
              const interpretationState = row.original.interpretations?.[0]?.interpretation_state;
              const interpretationValue = row.original.interpretations?.[0]?.interpretation_value;
              const color = getColor(interpretationState);
              const displayText = getDisplayText(interpretationState, interpretationValue);

              return (
                  <Box>
                      <Box sx={{ backgroundColor: color, p: 1, fontWeight: "bold", display: "flex", justifyContent: "center", borderRadius: 2 }}>
                          {displayText}
                      </Box>
                  </Box>
              );
          },
        },
          
          {
            accessorKey: 'interpretations.interpretation_classification',
            header: 'Classification',
            id: "interpretation_classification",
            filterFn: filterByClassification,
            Header:() => <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            Classification<br />&nbsp;
            </div>, 
            Cell: ({ row }) => {
              const interpretationClassification = row.original.interpretations?.[0]?.interpretation_classification;
              return (
                <Box>
                  {addSpaceToCamelCase(interpretationClassification) || ''}
                </Box>
              );
            },
          },
    ]


    // Define the type for the keys for handling persistance of table settings in session storage
    type ResultSessionStorageKeyType = keyof ResultSessionStorageModel;

    // Generic handler function for persisting table parameters
    const handleParameterChange = <T,>(
        key: ResultSessionStorageKeyType,
        currentValue: T,
        setValueFunction: (value: T) => void,
        newValue: T | ((prevValue: T) => T)
        ) => {
        const updatedValue = typeof newValue === 'function' ? (newValue as (prevValue: T) => T)(currentValue) : newValue;
        ResultTableParameterStorageService.set(key, updatedValue);
        setValueFunction(updatedValue);
        };

    // Handlers for persisting table: sorting, pagination, visibility, filter, search, and search bar visibility
    const setSortingFunction = (newSortingValue: any) =>
        handleParameterChange(ResultSessionStorageKey.sorting, sorting, setSorting, newSortingValue);
    const setPaginationChangeFunction = (newPaginationValue: any) =>
        handleParameterChange(ResultSessionStorageKey.pagination, pagination, setPagination, newPaginationValue);
    const setColumnVisibilityFunction = (newColumnVisibilityValue: any) =>
        handleParameterChange(ResultSessionStorageKey.columnVisibility, columnVisibility, setColumnVisibility, newColumnVisibilityValue);
    const setFilterChangeFunction = (newFilterChangeValue: any) =>
        handleParameterChange(ResultSessionStorageKey.columnFilters, columnFilters, setColumnFilters, newFilterChangeValue);
    const setGlobalFilterChangeFunction = (newGlobalFilterChangeValue: string | ((prevValue: string) => string)) =>
        handleParameterChange(ResultSessionStorageKey.search, globalFilter, setGlobalFilter, newGlobalFilterChangeValue);
    const setShowGlobalFilterChangeFunction = (newShowGlobalFilterChangeValue: any) =>
        handleParameterChange(ResultSessionStorageKey.showSearch, showGlobalFilter, setShowGlobalFilter, newShowGlobalFilterChangeValue);



    return <MaterialReactTable
            columns={columns}
            data={data}
            state={{
              isLoading,
              sorting,
              columnVisibility,
              columnFilters,
              pagination,
              globalFilter,
              showGlobalFilter
            }}
            onSortingChange={setSortingFunction}
            onColumnVisibilityChange={setColumnVisibilityFunction}
            onColumnFiltersChange={setFilterChangeFunction}
            onPaginationChange={setPaginationChangeFunction}
            onGlobalFilterChange={setGlobalFilterChangeFunction}
            onShowGlobalFilterChange={setShowGlobalFilterChangeFunction}
            defaultColumn={{    
              minSize: 10,
              maxSize: 160,
              size: 10,
            }}
            positionActionsColumn="last"
            enableRowSelection
            selectAllMode="all"
            
            positionPagination="top"
            enableBottomToolbar={false}
            initialState={{
              sorting,
              columnVisibility,
              columnFilters,
              pagination,
              globalFilter,
              density: 'compact',
              columnPinning: { left: ['mrt-row-select'], right: ['mrt-row-actions']},
            }}

          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: "Cycles", // Renames Actions Column
            },
          }}

          muiTableContainerProps={{
            // TABLE CONTAINER
              sx: {
                height: 'auto', // Allow the table to expand naturally
                maxHeight: 'calc(100vh - 300px)', // Sets height of the table to fix it in position              
              }
          }}

          muiTopToolbarProps={({ table }) => ({
            // TOP TOOLBAR (Export Data + filter buttons)
            sx: {
              position: 'sticky', // Pins Top Toolbar on scroll
              top: 0,
              zIndex: 1,
              overflowY: 'hidden',
              p: 0.5,
              paddingBottom: 0,
              color: colorConfigs.sidebar.color,
              '& .MuiToolbar-root': {
                width: "50%",
                padding: 0,
                margin: 0,
                paddingBottom: 0,
                paddingTop: 0,
              },
              '& .MuiBox-root':{
                p:0
              },
              '& .MuiIconButton-root': {
                color: colorConfigs.tables.headBg,
              },
              '& .MuiTextField-root': {
                p: 0,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: colorConfigs.tables.headBg,
                  },
                  '&:hover fieldset': {
                    borderColor: colorConfigs.tables.headBg,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colorConfigs.tables.headBg,
                  },
                },
                '& .MuiInputBase-input': {
                  color: colorConfigs.tables.headBg,
                },
                '& .MuiInputAdornment-root .MuiSvgIcon-root': {
                  color: colorConfigs.tables.headBg,
                },
              },
              '& .MuiTablePagination-root': {
                margin: 0,
                paddingTop: 0.5,
                paddingBottom: 0.5,
                color: colorConfigs.buttons.bg,
              },
              '& .MuiTablePagination-selectLabel': {
                color: colorConfigs.tables.headBg,
              },
              '& .MuiTablePagination-displayedRows': {
                color: colorConfigs.buttons.bg,
              },
              '& .MuiTablePagination-actions': {
                color: colorConfigs.tables.headBg,
              },
              '& .MuiInputAdornment-root .MuiSvgIcon-root': {
                color: colorConfigs.tables.headBg,
              },
            },
          })}

          muiTableHeadProps={{
            // WHOLE TABLE HEADER
            sx: {
              position: 'sticky',
              top: 0,
              zIndex: 1,
              overflowY: 'hidden',
            }
          }}

          muiTableHeadCellProps={({ column }) => ({
            // TABLE HEADER CELLS
            sx: {
              border: '1px solid rgba(255, 255, 255, 0.3)',
              backgroundColor: colorConfigs.tables.headBg,
              opacity: 0.95,
              color: "white",
              paddingBottom:0.2,
              paddingTop:0.2,
              ...(column.id === 'mrt-row-select' && { // Fix Select column width
                maxWidth: '50px',
                width: '50px',
              }),
              '& .MuiIconButton-root': {
                color: "white",
              },
              '& .MuiSvgIcon-root': {
                color: "white",
                p:0,
                m:0,
              },
              '& .MuiTableSortLabel-root': {
                color: 'white',
                // p:0,
                m:0,
                ml:1,
                mr: -0.5,
                '&.Mui-active': {
                  color: 'white',
                },
                '& .MuiTableSortLabel-icon': {
                  color: 'white !important',
                  marginLeft: 'auto',
                },
              },
              '& .MuiTableSortLabel-iconDirectionAsc': {
                color: 'white !important',
                p:0,
                m:0,
                ml:3
              },
              '& .MuiTableSortLabel-iconDirectionDesc': {
                color: 'white !important',
                p:0,
                m:0,
                ml:3
              },
            },
          })}
              
              
          muiFilterTextFieldProps={{
              // TABLE FILTER BUTTONS
              sx: {
                p:0,
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                color: "white",
                '& .MuiIconButton-root': {
                  color: "rgba(255, 255, 255, 0.5)",
                },
                '& .MuiInputBase-input': {
                  color: "white",
                  p: 0.5,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'none',
                },
                '& .MuiInput-underline:before': {
                  borderColor: 'none',
                },
                '&:hover .MuiInput-underline:before': {
                  borderColor: 'rgba(255, 255, 255, 0.5)',
                },
                '& .MuiInput-underline:after': {
                  borderColor: 'rgba(255, 255, 255, 0.5)',
                },
              },
            }}

          muiTableBodyRowProps={({ row }) => ({
              // Handles Row Click  
              onClick: (event) => {
                  console.log(row.original);
                  setResult(row.original)
                  setPopup("Edit")
              },
              sx: {
                  cursor: 'pointer',
                  backgroundColor: row.index % 2 === 0 ? colorConfigs.tables.firstRow : colorConfigs.tables.secondRow,
                  paddingBottom:0,
                  paddingTop:0,
              },
          })}

          muiTableBodyProps={{
            // TABLE BODY
            sx: {
              maxHeight: "100%",
              overflowY: 'auto', // Allows body to scroll
              '&::-webkit-scrollbar': { display: 'none' }, // Remove scroll bar
              '-ms-overflow-style': 'none', // Internet Explorer 10+
              'scrollbar-width': 'none', // Firefox
            }
          }}

          muiTableBodyCellProps={({ cell }) => ({
            // TABLE BODY CELLS
            sx: {
                paddingBottom:0.5,
                paddingTop:0.5,
                ...(cell.column.id === 'mrt-row-select' && { // Fix Select column width
                  maxWidth: '50px',
                  width: '50px',
                }),
            },
          })}

          muiBottomToolbarProps={{
              // BOTTOM TOOLBAR (Footer - currently disabled)
          }}

          muiPaginationProps={{
            // PAGINATION TOOLBAR PROPS
            rowsPerPageOptions: [500, 1000],
            sx: {
              '& .MuiTablePagination-root': {
              },
              '& .MuiToolbar-root': {
              },
              '& .MuiTablePagination-selectLabel': {
              },
              '& .MuiTablePagination-displayedRows': {
              },
            }
          }}


            renderTopToolbarCustomActions={({ table }) => {

                const handleArchiveResults = () => {
                    table.getSelectedRowModel().flatRows.map(row => {
                        //Add archive logic
                    });
                };

                const handleExportRows = (rows: MRT_Row<any>[]) => {                    
                  const now = dayjs().format('YYYY-MM-DD HH:mm:ss');
                  const filename = `${now}_${authData.user?.id}_Results_List`;
                  var excel_rows = []

                    for (var x = 0; x < rows.length; x++) {
                      const result = rows[x].original;
                      const formattedBatchExpiryDate = result.lot_expiry_date ? dayjs(result.lot_expiry_date).format('YYYY-MM-DD') : '';
                      const formattedResultTimestamp = result.timestamp ? dayjs(result.timestamp).format('YYYY-MM-DD HH:mm:ss') : '';

                      excel_rows[x] = {
                        "Result ID": result.id,
                        "Timestamp": formattedResultTimestamp,
                        "Test Type": result.test_type || '',
                        "User ID": result.created_by,
                        "User Email": result.createdby,
                        "Dog ID": result.dog,
                        "Dog Name": result.dog_name || '',
                        "Dog is Active": result.dog_is_active ? "Yes" : "No",
                        "Cycle ID": result.cycle,
                        "Cycle Name": result.cycle_name || '',
                        "Cycle Status": result.cycle_status ? "Active" : "Archived",
                        "Batch ID": result.lot,
                        "Batch Number": result.lot_number || '',
                        "Batch Expiry Date": formattedBatchExpiryDate,
                        "Batch Active": result.lot_is_active ? "Yes" : "No",
                        "Interpretation Value": result.interpretations[0]?.interpretation_value || '',
                        "Interpretation State": result.interpretations[0]?.interpretation_state || '',
                        "Interpretation Classification": result.interpretations[0]?.interpretation_classification || '',
                        "Interpretation Notes": result.interpretations[0]?.interpretation_notes || '',
                        "Result Notes": result.notes || '',
                        "TEST STRIPS": "",
                        "Baseline": result.test_strips[0]?.baseline.join(', '),
                        "Profile": result.test_strips[0]?.profile.join(', '), 
                        "Control Line Score": result.test_strips[0]?.c_line.score,
                        "Control Line Peak Position": result.test_strips[0]?.c_line.peak_position,
                        "Test Line Peak Position": result.test_strips[0]?.lines[0]?.peak_position,
                        "Test Line Score": result.test_strips[0]?.lines[0]?.score,
                        "Test Line TC Ratio": result.test_strips[0]?.lines[0]?.tc_ratio,
                        "DEVICE": "",
                        "App Version": result.app_version,
                        "App Build": result.app_build,
                        "Device Model": result.device_model,
                        "OS Version": result.os_version,
                        "Image": result.full_image,

                      }
                    }
                    tsXLXS().exportAsExcelFile(excel_rows).saveAsExcelFile(filename);
                  };
                

                return (
                  <div style={{ width: showGlobalFilter ? "55%": "80%", display: 'flex', gap: '2rem', justifyContent: "space-between", transition: showGlobalFilter ? "width 0.1s ease-in" : "width 0.5s ease-out"}}>
                  <div style={{ position: 'relative', height: 'auto', display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                    {/* <Button
                      fullWidth
                      onClick={() => setPopup("Create")}
                      variant="contained"
                      id="demo-customized-button3"
                      disableElevation
                      sx={{ 
                        background: colorConfigs.buttons.bg, 
                        color: 'white', 
                        minWidth: "15em",
                        width: "fit-content",
                        height: "fit-content",
                        textTransform: 'none', 
                        transition: 'transform 0.3s ease', 
                        fontWeight: 'bold', 
                        '&:hover': { transform: 'scale(1.03)' } 
                      }}                
                    >
                      Create
                    </Button> */}
                    <div style={{minWidth: "15.5em", height: "100%", position: 'absolute', top: '41px', display: "flex", gap: "0.4em"}}>      
                    <Button
                      id="demo-customized-button"
                      aria-controls={open ? 'demo-customized-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      variant="contained"
                      disableElevation
                      onClick={handleClick}
                      endIcon={<KeyboardArrowDownIcon />}
                      sx={{ width: "fit-content", background: colorConfigs.buttons.bg, color: "white", textTransform: 'none', transition: 'transform 0.3s ease', fontWeight: "bold", '&:hover': { transform: 'scale(1.03)' } }}
                    >
                      Export Data
                    </Button>
                    <Tooltip title="Refresh Data" placement="right">
                    <Button
                      id="demo-customized-button1"
                      variant="contained"
                      disableElevation
                      onClick={() => fetchData()}
                      sx={{ 
                        background: colorConfigs.buttons.bg, 
                        color: "white", 
                        textTransform: 'none', 
                        transition: 'transform 0.3s ease', 
                        fontWeight: "bold", '&:hover': { transform: 'scale(1.03)' },
                        animation: needToRefreshData ? 'growShrink 0.5s infinite alternate' : 'none',

                      }}
                    >
                      <RefreshIcon sx={{ p: 0, m: 0 }} />
                    </Button>
                    </Tooltip>
                    </div>  
                  </div>
                  <Button
                    id="demo-customized-button1"
                    variant="contained"
                    disableElevation
                    onClick={() => handleRemoveAllFilters()}
                    sx={{ background: colorConfigs.buttons.bg, color: "white", textTransform: 'none', transition: 'transform 0.3s ease', fontWeight: "bold", '&:hover': { transform: 'scale(1.03)' } }}
                  >
                    Reset Filters
                  </Button>
                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem disabled={
                        !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                      } onClick={() => handleExportRows(table.getSelectedRowModel().rows)} disableRipple>
                        Export to Excel
                      </MenuItem>
                    </StyledMenu>
                    </div>
                );
            }}
        />
}
 
export default ResultsList;