import { Outlet } from "react-router-dom";
import { Box, styled, Toolbar } from "@mui/material";
import sizeConfigs from "../../configs/sizeConfigs";
import Sidebar from "../common/Sidebar";
import Topbar from "../common/Topbar";
import colorConfigs from "../../configs/colorConfigs";

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);
const pageWidth = `calc(100vw - ${sizeConfigs.sidebar.halfwidth})`

const MainLayout = () => {
  return (
    <Box sx={{ display: "flex", m:0 }}>
      <Topbar />
      <Box 
        component="nav"
        sx={{
          width: sizeConfigs.sidebar.halfwidth,
          flexShrink: 0,
          overflowY: "auto",
        }}
      >
        <Sidebar />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          paddingX: 3,
          paddingTop: 8,
          width: `calc(100vw - ${sizeConfigs.sidebar.halfwidth})`,
          height: "100vh",
          backgroundColor: colorConfigs.topbar.bg2,
          overflowY: 'auto',
          '&::-webkit-scrollbar': { display: 'none' },
          '-ms-overflow-style': 'none', // Internet Explorer 10+
          'scrollbar-width': 'none', // Firefox
          overflowX: "hidden"
        }}
      > 
        <Outlet/>
      </Box>
      
    </Box>
  );
};

export default MainLayout;