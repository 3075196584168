const colorConfigs = {
    sidebar: {
        bg: "#e7e7e7",
        // bi: "linear-gradient(180deg, #4A90E2 0%, #132036 85%, #132036 100%)",
        color: "#359BDB",
        hoverBg: "#86589B",
        activeBg: "#86589B"
    },
    topbar: {
        bg: "#359BDB",
        color: "#359BDB",
        bg2: "#FBFBFB"
    },
    mainBg: "#fff",
    buttons: {
        bg: "#359BDB",
        active: "#32cd32",
        inactive: "#d0d0d0",
        memorialised: "#DAA520"
    },
    tables: {
        headBg: "#86589B",
        mainBg: "#e7e7e7",
        firstRow: "#fff",
        secondRow: "rgba(256, 250, 260)"
    },
    result: {
        ABOVE: "#FF4141",
        VALID: "#A7E531",
        BELOW: "#FFE103",
        INVALID: "#a7a7b7",
        red: "#FF4141",
        yellow: "#A7E531",
        green: "#FFE103",
        invalid: "#a7a7b7",
        cpeak: "#FFA53B",
        tpeak: "#86589B",
        profile: "#359BDB",
        baseline: "#FF4141",
    },
    input: {
        color: "#86589B",
    },
    dashboard: {
        slider:  "#359BDB",
        border:  "rgba(134, 88, 155, 0.9)"
    }
};

export default colorConfigs;

// #E5F9FF light blue
// #359BDB darker blue
// #132036 dark blue

// Colour scheme
// RED #FF4141
// GREEN #A7E531
// YELLOW #FFE103
// ORANGE #FFA53B
// PURPLE #86589B
// PINK #FF7F7F
// BLACK #1D1D1D
// GREY #E5E5E5
// BLUE #029BDC
