import React from 'react'
import { FieldProps, getIn } from 'formik'
import { InputAdornment, TextField, TextFieldProps } from '@mui/material'
import assets from '../../assets'


/**
 * Material TextField Component with Formik Support including Errors.
 * Intended to be specified via the `component` prop in a Formik <Field> or <FastField> component.
 * Material-UI specific props are passed through.
 */
export const FormEmailTextField: React.FC<FieldProps & TextFieldProps> = props => {
  const isTouched = getIn(props.form.touched, props.field.name)
  const errorMessage = getIn(props.form.errors, props.field.name)

  const { error, helperText, field, form, ...rest } = props

  return (
    <TextField
    //   variant="outlined"
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
      {...rest}
      {...field}
      fullWidth
      margin="normal"
      autoFocus
      InputProps={{
        startAdornment: (
            <InputAdornment position="start">
                <img src={assets.images.emailicon} alt="emailicon" width="25px" height="20px" />
            </InputAdornment>
        )
    }}
    />
  )
}

export const FormPasswordTextField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)
  
    const { error, helperText, field, form, ...rest } = props
  
    return (
      <TextField
      //   variant="outlined"
        error={error ?? Boolean(isTouched && errorMessage)}
        helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
        {...rest}
        {...field}
        fullWidth
        margin="normal"
        // label="Password"
        type="password"
      />
    )
  }
