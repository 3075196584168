import { Card, Grid, Paper, InputLabel, Table, TableRow, TableCell, Button } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Field, FormikProvider, useFormik } from 'formik';

import * as Yup from "yup";
//import PostService from "../../../services/post.service";
import axios from 'axios';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import ResultDetailGraph from '../graphs/ResultDetailGraph';
import { useTheme } from '@mui/material/styles';
import { CPeakField, 
    CScoreField, 
    FormVisualResultsField, 
    ImageField, 
    NotesField, 
    TCRatioField, 
    TPeakField, 
    TScoreField,
    ResultDetailReadOnlyField,
    TextAdornmentReadOnlyField,
    TextClassificationReadOnlyField} from '../fields/fields';
import colorConfigs from '../../../configs/colorConfigs';

type InterpretationState = 'ABOVE' | 'VALID' | 'BELOW' | 'INVALID'


interface ResultDetailProps {
  result: any;
  create: boolean;
  handleSaveButton: () => void;
  handleBackButton: () => void;
}

interface LineData {
    score: number | string;
    peak_position: number | string;
    tc_ratio: number;
  }
  
  interface GraphData {
    test_lines: LineData[];
    cscore: number | string;
    cpeak: number | string;
    profile: string[];
    baseline: string[];
    quantitativeresult: number | string;
    thesholdmin: number | string;
    thesholdmax: number | string;
  }

axios.defaults.baseURL = window.env.API_URL;
// const access = localStorage.getItem("access")
const loginpage = "/auth/login"

const phoneRegEx = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const ResultDetailCard: React.FC<ResultDetailProps> = ({result, create, handleSaveButton, handleBackButton}) => {
    const [viewedResult, setViewedResult] = useState<any[]>([])
    const [graphData, setGraphData] = useState<GraphData>({
        test_lines: [],
        cscore: '',
        cpeak: '',
        profile: [''],
        baseline: [''],
        quantitativeresult: '',
        thesholdmin: '',
        thesholdmax: '', })
    const [testImage, setTestImage] = useState<any>()
    const [stripImages, setStripImages] = useState<any[]>([])
    const [lines, setLines] = useState<any[]>([])
    // const navigate = useNavigate();
    const location = useLocation();

    const getColor = (state: InterpretationState) => colorConfigs.result[state] || colorConfigs.result.INVALID;

    const getDisplayText = (interpretationState: any, interpretationValue: any) => {
        if (!interpretationValue) return interpretationState;
        switch (interpretationState) {
            case "BELOW":
                return `< ${interpretationValue}`;
            case "ABOVE":
                return `> ${interpretationValue}`;
            case "VALID":
                return interpretationValue;
            default:
                return interpretationState;
        }
      };

    const validationSchema = () => {
        return Yup.object().shape({
        });
    }

    const theme = useTheme();


    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            app_build: '',
            app_version: '',
            device_model: '',
            device_uuid: '',
            full_image: '',
            id: '',
            latitude: '',
            longitude: '',
            lot_number: '',
            lot_id: '',
            lot_is_active: false,
            lot_id_and_number: '',
            cycle_id: '',
            cycle_name: '',
            cycle_status: false,
            cycle_id_and_name: '',
            dog_id: '',
            dog_name: '',
            dog_is_active: false,
            dog_id_and_name: '',
            user_id: '',
            user_email: '',
            user_is_active: false,
            notes: '',
            organisation_id: '',
            os_version: '',

            // Test lines
            baseline: '',
            cline_score: '',
            cline_peak_position: '',
            // Lines
            lines: [],
            tline_1_score: '',
            tline_1_peak_position: '',
            tline_1_ratio: '',
            tline_2_score: '',
            tline_2_peak_position: '',
            tline_2_ratio: '',
            profile: '',

            interpretation_value: '',
            interpretation_state: '',
            interpretation_notes: '',
            interpretation_classification: '',
            result_color: '',

            test_type: '',
            timestamp: '',
        },
        validationSchema,
        onSubmit: (values) => {
        },
    });

    useEffect(() => {
                console.log("Result Data", result)
                setViewedResult(result)
                setTestImage(result.full_image)
                // setTestImage("https://pub.mdpi-res.com/biosensors/biosensors-11-00211/article_deploy/html/images/biosensors-11-00211-g001.png?1627912606")

                console.log("Image", [result.full_image])
                setGraphData({
                    test_lines: result.test_strips[0].lines,
                    cscore: result.test_strips[0].c_line.score,
                    cpeak: result.test_strips[0].c_line.peak_position,
                    profile: result.test_strips[0].profile,
                    baseline: result.test_strips[0].baseline,
                    quantitativeresult: 555, // DO WE NEED THIS
                    thesholdmin: 30,  // DO WE NEED THIS
                    thesholdmax: 1000,  // DO WE NEED THIS
                })

                formik.setFieldValue('app_build', result.app_build);
                formik.setFieldValue('app_version', result.app_version);
                formik.setFieldValue('device_model', result.device_model);
                formik.setFieldValue('device_uuid', result.device_uuid);
                formik.setFieldValue('full_image', result.full_image);
                formik.setFieldValue('id', result.id);
                formik.setFieldValue('lot_number', result.lot_number);
                formik.setFieldValue('lot_id', result.lot);
                formik.setFieldValue('lot_is_active', result.lot_is_active);
                formik.setFieldValue('lot_id_and_number', `${result.lot} ( ${result.lot_number} )`);
                formik.setFieldValue('notes', result.notes);
                formik.setFieldValue('os_version', result.os_version);
                formik.setFieldValue('cycle_id', result.cycle);
                formik.setFieldValue('cycle_status', result.cycle_status);
                formik.setFieldValue('cycle_name', result.cycle_name);
                formik.setFieldValue('cycle_id_and_name', `${result.cycle} ${result.cycle_name}`);
                formik.setFieldValue('dog_id', result.dog);
                formik.setFieldValue('dog_name', result.dog_name);
                formik.setFieldValue('dog_is_active', result.dog_is_active);
                formik.setFieldValue('user_id', result.created_by);
                formik.setFieldValue('user_email', result.createdby);
                formik.setFieldValue('user_is_active', result.user_is_active);
                formik.setFieldValue('test_type', result.test_type)
                formik.setFieldValue('timestamp', dayjs(result.timestamp).format('YYYY-MM-DD HH:mm:ss'));

                // Test Strips
                formik.setFieldValue('cline_score', parseFloat(result.test_strips[0].c_line.score).toFixed(2));
                formik.setFieldValue('cline_peak_position', parseFloat(result.test_strips[0].c_line.peak_position).toFixed(2));
                
                // Setting lines as an array to dynamically render these
                formik.setFieldValue('lines', result.test_strips[0].lines);
                setLines(result.test_strips[0].lines)
                
                // Dynaically sets tline score, peak and tc_ratio
                result.test_strips[0].lines.map((line:any, index:any) => {
                    formik.setFieldValue(`tline_${index+1}_score`, parseFloat(line.score).toFixed(2));
                    formik.setFieldValue(`tline_${index+1}_peak_position`, parseFloat(line.peak_position).toFixed(2));
                    formik.setFieldValue(`tline_${index+1}_ratio`, parseFloat(line.tc_ratio).toFixed(2));
                })
            
                const value = result.interpretations[0]?.interpretation_value
                const state = result.interpretations[0]?.interpretation_state
                const color = getColor(state);
                // Set Result Color
                formik.setFieldValue('result_color', color)
                // Set interpretation value and and state
                const displayValue = getDisplayText(state, value);
                formik.setFieldValue(`interpretation_value`, displayValue);
                formik.setFieldValue(`interpretation_state`, result.interpretations[0]?.interpretation_state);
                formik.setFieldValue(`interpretation_notes`, result.interpretations[0]?.interpretation_notes);

                // Helper function to add space before uppercase letters as classification has no spaces e.g. "HighProjesteron"
                const addSpaceToCamelCase = (text: any) => {
                    return text.replace(/([a-z])([A-Z])/g, '$1 $2');
                };
                const interpretationClassification = result.interpretations[0]?.interpretation_classification;
                const formattedClassification = interpretationClassification ? addSpaceToCamelCase(interpretationClassification) : '';
                formik.setFieldValue('interpretation_classification', formattedClassification);        
    
    }, []);

    return (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} style={{overflow: 'auto' }}>
            <Card sx={{height: "100%", boxShadow: 10, p:1, paddingTop: 0.5, marginX: 5, marginTop: 2, marginBottom: 3, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", overflowX: "auto"}}>
            <div style={{width: "100%", height: "100%", margin: "auto", padding: "1em", display: "flex", alignItems: "center"}}>
                <Grid container spacing={3} wrap="nowrap" paddingTop="5px" alignItems="top" justifyContent="flex-start" style={{ minWidth: "100%" }}>
                    <Grid item xs={12} sm={6} md={3.5} style={{ display: "flex", flexDirection: "column", minWidth: "300px" }}>

                            <Card sx={{ boxShadow: 10, p:2, height: "100%"}}>
                                <div className="form-group">
                                    <Field name="timestamp" type="text" className="form-control" label="Timestamp" component={ResultDetailReadOnlyField}  />
                                    <Field name="test_type" type="text" className="form-control" label="Test Type" component={ResultDetailReadOnlyField}  />
                                    <Field name="user_email" type="text" id={formik.values.user_id} status={formik.values.user_is_active} className="form-control" label="User" component={TextAdornmentReadOnlyField} />
                                    <Field name="lot_number" type="text"  id={formik.values.lot_id} status={formik.values.lot_is_active} className="form-control" label="Batch" component={TextAdornmentReadOnlyField} />
                                    <Field name="cycle_name" type="text" id={formik.values.cycle_id} status={formik.values.cycle_status} className="form-control" label="Cycle" component={TextAdornmentReadOnlyField} />
                                    <Field name="dog_name" type="text" id={formik.values.dog_id} status={formik.values.dog_is_active} className="form-control" label="Dog" component={TextAdornmentReadOnlyField} />
                                </div>
                            </Card>
                    </Grid>
                    <Grid item xs={12} md={5} style={{ minWidth: "590px", display: "flex", flexDirection: "column",  }}>
                            <Card sx={{ boxShadow: 10, p:2, height: "100%"}}>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <div style={{display: "flex", gap: "20px", width: "540px"}}>
                                        <Field name="nothing" visualResult={formik.values.interpretation_value} resultColor={formik.values.result_color} type="text" className="form-control" label="Result Value" component={FormVisualResultsField} />
                                        <Field name="interpretation_classification" type="text" className="form-control" label="Classification" component={TextClassificationReadOnlyField}  />
                                </div>
                                <ResultDetailGraph graphdata = {graphData}/>
                            </div>
                            <div style={{width: "100%", marginTop: 8, display: "flex", justifyContent: "center"}}>
                            <Table sx={{width: "100%", align: "center"}} >
                            {lines.map((line, index) => {
                              return (
                                <TableRow key={index} sx={{p: 0, m:0}}>
                                    <TableCell sx={{p: 0, m:0}}>                                    
                                        <InputLabel
                                            sx={{
                                                paddingX: 1,
                                                paddingTop: 4,
                                                fontSize: 22,
                                                transform: 'translate(0, -1.5px) scale(0.75)', // Mimic shrunken transformation
                                                transformOrigin: 'top left',                                            
                                                fontWeight: 1000,                                          
                                            }}
                                        >
                                            Test line {index + 1}
                                        </InputLabel>
                                    </TableCell>
                                    <TableCell sx={{p: 0, m:0}}><Field name={`tline_${index + 1}_score`} type="text" className="form-control" component={TScoreField} /></TableCell>
                                    <TableCell sx={{p: 0, m:0}}><Field name={`tline_${index + 1}_peak_position`} type="text" className="form-control" component={TPeakField} /></TableCell>
                                    <TableCell sx={{p: 0, m:0}}><Field name={`tline_${index + 1}_ratio`} type="text" className="form-control" component={TCRatioField} /></TableCell>
                                </TableRow>      
                              )
                            })}
                                <TableRow sx={{p: 0, m:0}}>
                                    <TableCell sx={{p: 0, m:0}}>                                    
                                        <InputLabel
                                            sx={{
                                                paddingX: 1,
                                                paddingTop: 4,
                                                fontSize: 22,
                                                transform: 'translate(0, -1.5px) scale(0.75)', // Mimic shrunken transformation
                                                transformOrigin: 'top left',                                            
                                                fontWeight: 1000,  
                                            }}
                                        >
                                            Control line
                                        </InputLabel>
                                    </TableCell>                                    
                                    <TableCell sx={{p: 0, m:0}}><Field name="cline_score" type="text" className="form-control" component={CScoreField} /></TableCell>
                                    <TableCell sx={{p: 0, m:0}}><Field name="cline_peak_position" type="text" className="form-control" component={CPeakField} /></TableCell>
                                    <TableCell sx={{p: 0, m:0}}></TableCell>
                                </TableRow>
                            </Table>
                            </div>
                            </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3.5} style={{ display: "flex", flexDirection: "column", minWidth: "300px" }}>
                            <Card sx={{ boxShadow: 10, p:2, height: "100%"}}>

                            <div className="form-group">
                                {stripImages.map((image, index) => {
                                   const title = `Test strip image ${index +1}:`
                                   return (
                                    <Field key={index} name="nothing" image={image} type="text" className="form-control" label={title} component={ImageField} />
                                   )
                                })}
                                <Field name="nothing" image={testImage} type="text" className="form-control" label="Test Image" component={ImageField} />
                                <Field name="interpretation_notes" type="text" className="form-control" label="Interpretation Notes" component={NotesField}  />
                                <Field name="notes" type="text" className="form-control" label="Result Notes" component={NotesField}  />
                                <div style={{display: "flex", justifyContent: "space-between", gap:"10px"}}>
                                    <Field name="device_model" type="text" className="form-control" label="Device Model" component={ResultDetailReadOnlyField} />
                                    <Field name="os_version" type="text" className="form-control" label="OS Version" component={ResultDetailReadOnlyField}  />
                                </div>
                                <div style={{display: "flex", justifyContent: "space-between", gap:"10px"}}>
                                    <Field name="app_version" type="text" className="form-control" label="App Version" component={ResultDetailReadOnlyField} />
                                    <Field name="app_build" type="text" className="form-control" label="App Build" component={ResultDetailReadOnlyField} />
                                </div>
                            </div>
                            </Card>
                    </Grid>
                </Grid>
            </div>
            <div className="form-group" style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                        <Button
                            className="form-group"
                            onClick={() => handleBackButton()}
                            variant="contained"
                            sx={{
                                position: "relative",
                                m: 1,
                                background: colorConfigs.buttons.bg,
                                color: "white",
                                textTransform: 'none',
                                fontSize: 16,
                            }}
                        >
                            Back
                        </Button>
                    </div>
            </Card>
            </form>
        </FormikProvider>
    )
}

export default ResultDetailCard;

{/* <TextField
//   variant="outlined"
error={error ?? Boolean(isTouched && errorMessage)}
helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
{...rest}
{...field}
fullWidth
variant="outlined"
margin="normal"
autoFocus

InputProps={{
    sx:{
        fontSize:"12px",
        paddingY:0.5
    },
    readOnly: true,
    startAdornment: (
        <InputAdornment position="start" style={{marginRight: 10}}>
            <Typography fontWeight="bold" sx={{p:0, m:0}}>
            {id}
            </Typography>
       </InputAdornment>
    ),
    endAdornment: (
        <InputAdornment position="end" style={{}}>
            {status ? <Typography sx={{ fontSize:"10px", marginLeft: 1, paddingX:0.7, border: `1.5px solid ${colorConfigs.buttons.active}`, borderRadius: 2, color: colorConfigs.buttons.active }} >Active</Typography> : <Typography sx={{ fontSize:"10px", marginLeft: 0.5, paddingX:0.7, border: `1.5px solid ${colorConfigs.buttons.inactive}`, borderRadius: 2, color: colorConfigs.buttons.inactive }} >Inactive</Typography> }
       </InputAdornment>
    )
}}
sx={{
  }}
InputLabelProps={{
    style: {
    },
}}
/> */}