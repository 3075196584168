import { Card, Grid, Typography, Button, FormControlLabel, Checkbox, TextField, Box, Alert, AlertTitle } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Field, FormikProvider, useFormik } from 'formik';

import * as Yup from "yup";
import axios from 'axios';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import {  TextEditField, TextEditReadOnlyField } from '../fields/fields';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import colorConfigs from '../../../configs/colorConfigs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axiosInstance from '../../../redux/features/auth/axiosAuthHelper';
import { BatchSessionStorageKey, BatchTableParameterStorageService } from '../../../services/sessionStorage';

interface BatchDetailProps {
  batch: any;
  create: boolean;
  handleSaveButton: () => void;
  handleBackButton: () => void;
}

axios.defaults.baseURL = window.env.API_URL;

const loginpage = "/auth/login"

const phoneRegEx = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const BatchDetailCard: React.FC<BatchDetailProps> = ({batch, create, handleSaveButton, handleBackButton}) => {
    const authData = useSelector((state: RootState) => state.auth);
    const [errors, setErrors] = useState<string | null>(null);
    const [initialValues, setInitialValues] = useState<any>({})
    const [updatedValues, setUpdatedValues] = useState<any>({})
    const [hasMadeChanges, setHasMadeChanges] = useState<boolean>(false)
    const [isActiveChecked, setIsActiveChecked] = useState(false);
    // const navigate = useNavigate();
    const location = useLocation();

    console.log("create", create)

    const validationSchema = () => {
        return Yup.object().shape({
            lot_number: Yup.string().required("This field is required"),
            expiry_date: Yup.string().required("This field is required"),
            control_line_threshold: Yup.string().required("This field is required").matches(/^[^,]*$/, "Commas (,) are not allowed"),
            parameter_a: Yup.number().required("This field is required"),
            parameter_b: Yup.number().required("This field is required"),
            parameter_c: Yup.number().required("This field is required"),
            parameter_d: Yup.number().required("This field is required"),
        });
    }


    const formik = useFormik({
        enableReinitialize: !create,
        validateOnChange: false,
        validateOnBlur: false,
        initialValues:
            (!create && batch) ? {
                id: batch.id,
                lot_number: batch.lot_number,
                expiry_date: dayjs(batch.expiry_date),
                is_active: batch.is_active,
                control_line_threshold: batch.control_line_threshold,
                parameter_a:  batch.parameters?.a,
                parameter_b:  batch.parameters?.b,
                parameter_c:  batch.parameters?.c,
                parameter_d:  batch.parameters?.d,
            }
            : {
                id: '',
                lot_number: '',
                expiry_date: dayjs(),
                is_active: true,
                control_line_threshold: "",
                parameter_a: "",
                parameter_b: "",
                parameter_c: "",
                parameter_d: "",
            },
        validationSchema,
        onSubmit: async (values) => {
            const formatted_date = values.expiry_date.format('YYYY-MM-DD')

            const data = {
                lot_number: values.lot_number,
                expiry_date: formatted_date,
                control_line_threshold: values.control_line_threshold,
                parameters:{
                    a: values.parameter_a,
                    b: values.parameter_b,
                    c: values.parameter_c,
                    d: values.parameter_d,
                },
                is_active: values.is_active,
                }
            const url = batch ? `/api/batches/${batch.id}/` : `/api/batches/`
            const method = batch ? 'patch' : 'post'

            try {
                console.log("data", data)
                await axiosInstance ({
                    method,
                    url,
                    data,
                    headers: {
                        'Authorization': `Bearer ${authData.access}`
                    },
                });
                setErrors(null);
                console.log("Lot successfully updated")
                handleSaveButton() // reset states and close popup
                const count = BatchTableParameterStorageService.get(BatchSessionStorageKey.count)
                BatchTableParameterStorageService.set(BatchSessionStorageKey.count, count + 1) // Updated count to not trigger refresh data button

            } catch(error: any) {
                if (error?.response?.status === 400) {
                    const firstErrorItem = Object.keys(error.response.data)[0];
                    setErrors(`${firstErrorItem}: ${error.response.data[firstErrorItem]}`);
                } else {
                    const errorText = `Unknown error updating lot`;
                    setErrors(errorText);
                    console.error(errorText, error);
                }
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },
    });

    //  Set checkbox state
    useEffect(() => {
        setIsActiveChecked(formik.values.is_active)
    }, [batch, formik.values.is_active]);

  

    // Effect to set initial form values from batch to check for form changes
    useEffect(() => {
        setTimeout(() => {
            setInitialValues(formik.initialValues);
            setUpdatedValues(formik.initialValues);
        }, 500);
        setHasMadeChanges(false);
    }, [batch]);

    // Update the updatedValues state when formik values change
    useEffect(() => {
        setUpdatedValues(formik.values);
    }, [formik.values]);

    // Function to check if form has changed so the save button becomes active on edit
    const hasFormChanged = (flatModel: Record<string, any>, formValues: Record<string, any>): boolean => {
        for (const key in formValues) {
            if (key === "expiry_date") {
                const formDate = dayjs(formValues[key]).format('YYYY-MM-DD');
                const initialDate = create ? dayjs(flatModel[key]).format('YYYY-MM-DD') : dayjs(formValues[key]).format('YYYY-MM-DD');
                if (formDate !== initialDate) {
                    console.log("Changed Date", formDate, initialDate)
                    return true;
                }
            } else {
                if (formValues[key]!= flatModel[key]) {
                    console.log("Changed Data", formValues[key], flatModel[key])
                    return true;
                }
            }
        }
        return false;
    };
            
    // Use Effect to check if any changes ahve been made to the form on Edit
    useEffect(() => {
        if (!create && batch) {
            if (Object.keys(initialValues).length > 0 && Object.keys(updatedValues).length > 0) {
                const formChanged = hasFormChanged(initialValues, updatedValues);
                setHasMadeChanges(formChanged);
            }
        }
    }, [updatedValues, initialValues, batch]);
    
    // Use Effect set save button active on load if Create and if changes are made on Edit
    useEffect(() => {
        if (create) {
            setHasMadeChanges(true);
        } else {
            setHasMadeChanges(false);
        }
    }, [create]);

    return (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} style={{overflow: 'auto'}}>
            <Card sx={{ height: "100%", boxShadow: 10, p:2, marginX: 5, marginY: 3, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            {errors && (
                <Alert severity="error" style={{ width: "55.5%" }}>
                    <AlertTitle>Error</AlertTitle>
                    {errors}
                </Alert>
            )}
            <div style={{width: "100%", height: "100%", margin: "auto", padding: "1em", display: "flex", alignItems: "center"}}>
                <Grid container spacing={5} alignItems="top" justifyContent="center" style={{ minWidth: "100%", padding: 5 }}>
                            <Grid item xs={12} md={4} style={{ display: "flex", flexDirection: "column",  }}>
                                <Card sx={{ boxShadow: 10, p:2, height: "100%"}}>
                                    <div className="form-group" style={{display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                    <Typography variant="h6" sx={{ color: colorConfigs.sidebar.color}} gutterBottom>                                            
                                            Batch Details
                                        </Typography> 
                                        {!create && <Field name="id" disableds type="text" className="form-control" label="ID" component={TextEditReadOnlyField}  />}
                                        <Field name="lot_number" type="text" className="form-control" label="Batch Number" component={TextEditField} />
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']} sx={{ marginTop: 1, marginBottom: 1 }}>
                                                <DatePicker
                                                    label="Expiry"
                                                    value={formik.values.expiry_date}
                                                    onChange={(newValue) => formik.setFieldValue(
                                                        'expiry_date', newValue)}
                                                />

                                            </DemoContainer>
                                        </LocalizationProvider>
                                        <Field name="control_line_threshold" type="number" className="form-control" label="Control Line Threshold" component={TextEditField}  />
                                        <FormControlLabel sx={{mt: 0.5}} control={<Checkbox checked={isActiveChecked} name="is_active" 
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: colorConfigs.buttons.active,
                                                },
                                            }}
                                            onChange={(e) => {
                                                    setIsActiveChecked(!isActiveChecked)
                                                    formik.handleChange(e);
                                                }} className="form-control" />} 
                                                label={
                                                    <Box display="flex" alignItems="center">
                                                      Batch is
                                                      <Typography 
                                                        fontSize="smaller" 
                                                        sx={{ 
                                                          paddingX: 0.7, 
                                                          border: isActiveChecked ? `2px solid ${colorConfigs.buttons.active}` : `1px solid ${colorConfigs.buttons.inactive}`, 
                                                          borderRadius: 2, 
                                                          color: isActiveChecked ? colorConfigs.buttons.active : colorConfigs.buttons.inactive,
                                                          marginLeft: 1
                                                        }}
                                                      >
                                                        {isActiveChecked ? 'Active' : 'Inactive'}
                                                      </Typography>
                                                    </Box>
                                                  } 
                                                />
                                        </div>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={3} style={{ display: "flex", flexDirection: "column",  }}>
                            <Card sx={{ boxShadow: 10, p:2, height: "100%"}}>
                                <div className="form-group" style={{display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <Typography variant="h6"  sx={{color: colorConfigs.sidebar.color}} gutterBottom>
                                    Parameters
                                </Typography> 
                                <Field name="parameter_a" type="number" className="form-control" label="A" component={TextEditField} />
                                <Field name="parameter_b" type="number" className="form-control" label="B" component={TextEditField} />
                                <Field name="parameter_c" type="number" className="form-control" label="C" component={TextEditField} />
                                <Field name="parameter_d" type="number" className="form-control" label="D" component={TextEditField} />
                                </div>
                                </Card>
                            </Grid>
                            </Grid>
                    </div>
                    <div className="form-group" style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                        <Button
                            className="form-group"
                            onClick={() => handleBackButton()}
                            variant="contained"
                            sx={{
                                position: "relative",
                                m: 1,
                                background: colorConfigs.buttons.bg,
                                color: "white",
                                textTransform: 'none',
                                fontSize: 16,
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            className="form-group"
                            type="submit"
                            variant="contained"
                            disabled={!hasMadeChanges}
                            sx={{
                                type:"submit",
                                position: "relative",
                                m: 1,
                                background: colorConfigs.buttons.bg,
                                color: "white",
                                textTransform: 'none',
                                fontSize: 16,
                                animation: hasMadeChanges ? 'growShrink 0.5s infinite alternate' : 'none',
                                '&.Mui-disabled': {
                                    color: "white", // Text color when disabled
                                    opacity: 0.5,
                                },
                            }}
                        >
                            Save Changes
                        </Button>
                    </div>
                </Card>
            </form>
        </FormikProvider>
    )
}

export default BatchDetailCard;