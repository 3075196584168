import { Grid } from '@mui/material'
import ResetPasswordCard from '../../components/common/cards/ResetPasswordCard';

type Props = {}

const ResetPasswordPage = (props: Props) => {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Grid container style={{ marginLeft: "auto", marginRight: "auto", paddingTop: "1%", paddingBottom: "2%", overflow: "hidden" }}>
        <Grid item xs={12}>
          <div style={{ display: "flex", alignItems: "center", height: "100%", width: "100%" }}>
            <ResetPasswordCard />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default ResetPasswordPage