import React, { useState, useEffect, useRef } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { Box, Container, Card, CssBaseline, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';
import assets from '../../assets';
import colorConfigs from '../../configs/colorConfigs';
import axiosInstance from '../../redux/features/auth/axiosAuthHelper';

const ActivationStatusPage = () => {
    const [isSubmit, setSubmit] = useState(false);
    const [authFailed, setAuthFailed] = useState(false);
    const [loading, setLoading] = useState(true);
    const [displayMessage, setDisplayMessage] = useState("");
    const { token } = useParams();
    const alertContent = "Error requesting new activation email, please navigate to https://belly-labs.com for support.";
    const hasActivated = useRef(false); // Ref to ensure activation is triggered only once

    useEffect(() => {
        if (token && !hasActivated.current) {
            activateUser(token);
            hasActivated.current = true; // Set ref to true after activation attempt
        } else {
            setDisplayMessage("Invalid activation link.");
            setLoading(false);
        }
    }, [token]);

    const activateUser = async (token: any) => {
        try {
            console.log(`/api/auth/activate/${token}`)
            const response = await axiosInstance.get(`/api/auth/activate/${token}`);
            const payload = response.data;
            console.log(response.data)

            // Extract tokens from payload
            const { access_token, refresh_token, message } = payload;
            console.log("access_token", access_token)
            console.log("refresh_token", refresh_token)

            // Check the user's device, Android, IOS or non phone
            const userAgent = navigator.userAgent || (navigator as any).vendor || (window as any).opera;
            let deeplink = "";

            if (/android/i.test(userAgent)) {
                console.log("attempting to redirect to Android APP", `bellylabs://login?access=${access_token}&refresh=${refresh_token}`)
                setDisplayMessage(message);
                deeplink = access_token && refresh_token ? `bellylabs://login?access=${access_token}&refresh=${refresh_token}` : 'bellylabs://' ;
                window.location.href = deeplink;
            } else if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
                console.log("attempting to redirect to IOS APP", `bellylabs://login?access=${access_token}&refresh=${refresh_token}`)
                setDisplayMessage(message);
                deeplink = access_token && refresh_token ? `bellylabs://login?access=${access_token}&refresh=${refresh_token}` : 'bellylabs://' ;
                window.location.href = deeplink;
            } else {
                console.log(message)
                setDisplayMessage(message);
            }
        } catch (error) {
            console.error('Activation failed:', error);
            console.log(error);
            setAuthFailed(true);
            setDisplayMessage("Activation failed. Please navigate to https://belly-labs.com for support.");
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setSubmit(true);
    };

    return (
        <div style={{ marginLeft: "auto", marginRight: "auto", paddingTop: "5%" }}>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box
                component="img"
                sx={{
                    height: { xs: "50%", sm: "50%", md: "50%" },
                    width: { xs: "50%", sm: "50%", md: "50%" },
                    maxHeight: { xs: 433, md: 255 },
                    maxWidth: { xs: 550, md: 394 },
                    mb: 4,
                }}
                alt="Bellylabs logo"
                src={assets.images.blLogo}
            />
        </div>
        <Card sx={{ height: { xs: "12rem", sm: "16rem",  md: "20rem" }, display: 'flex', width: { xs: "20rem", sm: "30rem",  md: "40rem" }, justifyContent: 'center', alignItems: "center", boxShadow: 10, padding: { xs: "5%", sm: "7%", md: "10%" }, borderRadius: 5, overflow: "auto" }}>
            <Container component="main" sx={{ display: 'flex', justifyContent: 'center' }}>
                <CssBaseline />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ height: "100%", width: "100%", mt: 2, display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: "center" }}>
                                <Typography 
                                variant="h6" 
                                fontWeight="bold" 
                                sx={{ paddingY: 2, textAlign: 'center', fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' } 
                            }}>
                                {displayMessage}
                                </Typography>
                        </Box>
                    )}
                </Box>
            </Container>
        </Card>
    </div>
    );
};

export default ActivationStatusPage;
