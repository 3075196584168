import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { login, getProfile, refreshToken, isLoggedIn } from './authService';
import { RootState } from '../../store';
import { clearAllSessionStorage } from '../../../services/sessionStorage';

interface UserProfile {
    id: number | undefined;
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    is_superuser: boolean;
    is_staff: boolean;
    is_active: boolean;
    date_joined: string;
    last_login: string;
    user_type: string;
    units: string;
    address: string;
    image: string;
    result_filters: any[];
    // feedback_filters: any[];
    // user_filters: any[];
}

interface AuthState {
    access: string | null;
    isAuthenticated: boolean;
    user: UserProfile | null;
    loading: boolean;
    error: string | null;
    isSuperUser: boolean;
    // isAdmin: boolean;
    isPortalUser: boolean;
}

interface LoginPayload {
    email: string;
    password: string;
}

interface LoginResponse {
    access: string;
}

const initialState: AuthState = {
    access: null,
    isAuthenticated: false,
    user: null,
    loading: false,
    error: null,
    isSuperUser: false,
    // isAdmin: false,
    isPortalUser: false,
    // organisation: null,
};

// Login User and save access and refresh tokens in auth state
export const loginUser = createAsyncThunk<LoginResponse, LoginPayload, { state: RootState }>(
    'auth/loginUser',
    async ({ email, password }, { dispatch, rejectWithValue }) => {
        try {
            const data = await login(email, password);
            const { access } = data;

            dispatch(setCredentials({ access }));
            await dispatch(fetchUserProfile()).unwrap();

            return { access };
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Unknown error during login');
        }
    }
);

// Handles refresh token and saves new access and refresh tokens in auth state
export const refreshAccessToken = createAsyncThunk(
    'auth/refreshAccessToken',
    async (_, { getState, dispatch, rejectWithValue }) => {
        const state = getState() as RootState;
        const access = state.auth.access;

        if (!access) {
            return rejectWithValue('No access token available');
        }

        if (!isLoggedIn(access)) {
            try {
                const data = await refreshToken();
                const { access } = data;
                if (access) {
                    dispatch(setCredentials({ access }));
                } else {
                    dispatch(logout());  // Logout if refresh failed
                }

                return { access };
            } catch (error) {
                console.error("Refresh token failed:", error);
                dispatch(logout());  // Logout if there is an error during refresh
                return rejectWithValue(error);
            }
        }

        return { access };
    }
);

// Handles adding profile information to auth state.  Happens during LoginUser above
export const fetchUserProfile = createAsyncThunk<UserProfile, void, { state: RootState }>(
  'auth/fetchUserProfile',
  async (_, { getState, rejectWithValue }) => {
    const state = getState();
    const access = state.auth.access;

    if (access) {
      try {
        const userProfile = await getProfile(access);
        return userProfile;
      } catch (error: any) {
        return rejectWithValue(error.response?.data || 'Unknown error fetching user profile');
      }
    } else {
      return rejectWithValue('Access token not found');
    }
  }
);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, action: PayloadAction<{ access: string}>) => {
            state.access = action.payload.access;
            state.isAuthenticated = true;
        },
        logout: (state) => {
            localStorage.clear();
            clearAllSessionStorage()
            Object.assign(state, initialState);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.error = action.payload as string | null;
                state.loading = false;
            })
            .addCase(fetchUserProfile.fulfilled, (state, action) => {
                state.user = action.payload;
                state.isAuthenticated = true;
                state.isSuperUser = action.payload.is_superuser;
                // state.isAdmin = action.payload.user_type === "ADMIN";
                state.isPortalUser = action.payload.user_type === "PORTAL_USER";
                // state.isClientUser = action.payload.user_type === "CLIENT_USER";
                // state.organisation = action.payload.organisation?.toString() ?? null;
            })
            .addCase(fetchUserProfile.rejected, (state, action) => {
                state.error = action.payload as string | null;
            })
            .addCase(refreshAccessToken.fulfilled, (state, action) => {
                state.access = action.payload?.access || '';
                state.isAuthenticated = true;
            })
            .addCase(refreshAccessToken.rejected, (state, action) => {
                state.isAuthenticated = false;           
            });
    },
});

export const { setCredentials, logout } = authSlice.actions;
export default authSlice.reducer;

// SNIPPETS TO USE

// FETCH AUTH DATA
// const dispatch = useDispatch<AppDispatch>();
// const authData = useSelector((state: RootState) => state.auth); // Accessing the auth state
// console.log(authData.access)
// console.log(authData.user.id)

// LOGOUT
// const dispatch = useDispatch<AppDispatch>();
//     const handleLogout = () => {
//     dispatch(logout());
// };

// LOGIN
// const dispatch = useDispatch<AppDispatch>();
// const resultAction = await dispatch(loginUser({ username, password }));
// unwrapResult(resultAction); // If the promise is rejected, this will throw and you wont proceed

// AUTHENTICATION CHECK - No longer Needed
// const dispatch = useDispatch<AppDispatch>();
// useEffect(() => {
//   if (!authData.isAuthenticated) {
//       navigate(loginpage);
//       return;
//   }
//   if (!isLoggedIn()) {
//       dispatch(refreshAccessToken());
//   }
// }, [dispatch, navigate, authData.isAuthenticated]);