import { Card, Grid, Typography, Button, FormControlLabel, Checkbox, TextField, Box, Alert, AlertTitle } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Field, FormikProvider, useFormik } from 'formik';

import * as Yup from "yup";
import axios from 'axios';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import { TextEditReadOnlyField } from '../fields/fields';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import colorConfigs from '../../../configs/colorConfigs';
import axiosInstance from '../../../redux/features/auth/axiosAuthHelper';
import { CycleSessionStorageKey, CycleTableParameterStorageService } from '../../../services/sessionStorage';

interface CyclesDetailProps {
  cycle: any;
  create: boolean;
  handleSaveButton: () => void;
  handleBackButton: () => void;
}

axios.defaults.baseURL = window.env.API_URL;

const loginpage = "/auth/login"

const phoneRegEx = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const CyclesDetailCard: React.FC<CyclesDetailProps> = ({cycle, create, handleSaveButton, handleBackButton}) => {
    const authData = useSelector((state: RootState) => state.auth);
    const [errors, setErrors] = useState<string | null>(null);
    const [initialValues, setInitialValues] = useState<any>({})
    const [updatedValues, setUpdatedValues] = useState<any>({})
    const [hasMadeChanges, setHasMadeChanges] = useState<boolean>(false)
    const [isActiveChecked, setIsActiveChecked] = useState(false);
    const [isDogMemorialisedChecked, setIsDogMemorialisedChecked] = useState(false);
    const [isDogActiveChecked, setIsDogActiveChecked] = useState(false);
    // const navigate = useNavigate();
    const location = useLocation();

    console.log("create", create)

    const validationSchema = () => {
        return Yup.object().shape({
            lot_number: Yup.string().required("This field is required"),
            expiry_date: Yup.string().required("This field is required"),
            control_line_threshold: Yup.string().required("This field is required").matches(/^[^,]*$/, "Commas (,) are not allowed"),
            parameter_a: Yup.number().required("This field is required"),
            parameter_b: Yup.number().required("This field is required"),
            parameter_c: Yup.number().required("This field is required"),
            parameter_d: Yup.number().required("This field is required"),
        });
    }


    const formik = useFormik({
        enableReinitialize: !create,
        validateOnChange: false,
        validateOnBlur: false,
        initialValues:
            (!create && cycle) ? {
                id: cycle.id,
                dog: cycle.dog,
                start: dayjs(cycle.start).format('YYYY-MM-DD HH:mm:ss'),
                name: cycle.name,
                notes:  cycle.notes,
                status: cycle.status,
                dog_breed: cycle.dog_breed,
                dog_is_active: cycle.dog_is_active,
                dog_is_archived: cycle.dog_is_archived,
                dog_name: cycle.dog_name,
                total_results: cycle.total_results ? cycle.total_results : 0,
                user: cycle.user,
            }
            : {
                id: '',
                dog: '',
                start: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                name:'',
                notes: '',
                status: true,
            },
        validationSchema,
        onSubmit: async (values) => {
            const formatted_date = values.start

            const data = {
                dog: values.dog,
                start: formatted_date,
                name: cycle.name,
                notes: cycle.notes,
                status: cycle.status,
                }
            const url = cycle ? `/api/cycles/${cycle.id}/` : `/api/cycles/`
            const method = cycle ? 'patch' : 'post'

            try {
                console.log("data", data)
                await axiosInstance ({
                    method,
                    url,
                    data,
                    headers: {
                        'Authorization': `Bearer ${authData.access}`
                    },
                });
                setErrors(null);
                console.log("Lot successfully updated")
                handleSaveButton() // reset states and close popup
                const count = CycleTableParameterStorageService.get(CycleSessionStorageKey.count)
                CycleTableParameterStorageService.set(CycleSessionStorageKey.count, count + 1) // Updated count to not trigger refresh data button
            } catch(error: any) {
                if (error?.response?.status === 400) {
                    const firstErrorItem = Object.keys(error.response.data)[0];
                    setErrors(`${firstErrorItem}: ${error.response.data[firstErrorItem]}`);
                } else {
                    const errorText = `Unknown error updating lot`;
                    setErrors(errorText);
                    console.error(errorText, error);
                }
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },
    });

    //  Set checkbox state
    useEffect(() => {
        setIsActiveChecked(formik.values.status)
        setIsDogMemorialisedChecked(formik.values.dog_is_archived)
        setIsDogActiveChecked(formik.values.dog_is_active)
    }, [cycle, formik.values.status]);

  

    // Effect to set initial form values from cycle to check for form changes
    useEffect(() => {
        setTimeout(() => {
            setInitialValues(formik.initialValues);
            setUpdatedValues(formik.initialValues);
        }, 500);
        setHasMadeChanges(false);
    }, [cycle]);

    // Update the updatedValues state when formik values change
    useEffect(() => {
        setUpdatedValues(formik.values);
    }, [formik.values]);

    // Function to check if form has changed so the save button becomes active on edit
    const hasFormChanged = (flatModel: Record<string, any>, formValues: Record<string, any>): boolean => {
        for (const key in formValues) {
            if (key === "expiry_date") {
                const formDate = dayjs(formValues[key]).format('YYYY-MM-DD');
                const initialDate = create ? dayjs(flatModel[key]).format('YYYY-MM-DD') : dayjs(formValues[key]).format('YYYY-MM-DD');
                if (formDate !== initialDate) {
                    console.log("Changed Date", formDate, initialDate)
                    return true;
                }
            } else {
                if (formValues[key]!= flatModel[key]) {
                    console.log("Changed Data", formValues[key], flatModel[key])
                    return true;
                }
            }
        }
        return false;
    };
            
    // Use Effect to check if any changes ahve been made to the form on Edit
    useEffect(() => {
        if (!create && cycle) {
            if (Object.keys(initialValues).length > 0 && Object.keys(updatedValues).length > 0) {
                const formChanged = hasFormChanged(initialValues, updatedValues);
                setHasMadeChanges(formChanged);
            }
        }
    }, [updatedValues, initialValues, cycle]);
    
    // Use Effect set save button active on load if Create and if changes are made on Edit
    useEffect(() => {
        if (create) {
            setHasMadeChanges(true);
        } else {
            setHasMadeChanges(false);
        }
    }, [create]);

    return (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} style={{overflow: 'auto'}}>
            <Card sx={{ height: "100%", boxShadow: 10, p:2, marginX: 5, marginY: 3, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            {errors && (
                <Alert severity="error" style={{ width: "55.5%" }}>
                    <AlertTitle>Error</AlertTitle>
                    {errors}
                </Alert>
            )}
            <div style={{width: "100%", height: "100%", margin: "auto", padding: "1em", display: "flex", alignItems: "center"}}>
                <Grid container spacing={5} alignItems="top" justifyContent="center" style={{ minWidth: "100%", padding: 5 }}>
                            <Grid item xs={12} md={5} style={{ display: "flex", flexDirection: "column",  }}>
                                <Card sx={{ boxShadow: 10, p:2, height: "100%"}}>
                                    <div className="form-group" style={{display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                    <Typography variant="h6" sx={{ color: colorConfigs.sidebar.color}} gutterBottom>                                            
                                            Cycle Details
                                        </Typography> 
                                        <Box sx={{display: "flex", justifyContent: "space-between", gap: 2}}>
                                        <Field name="id" disableds type="text" className="form-control" label="Cycle ID" component={TextEditReadOnlyField}  />
                                        <Field name="total_results" type="text" className="form-control" label="Total Results" component={TextEditReadOnlyField} />
                                        </Box>
                                        <Field name="name" type="text" className="form-control" label="Cycle Name" component={TextEditReadOnlyField} />
                                        <Field name="start" type="text" className="form-control" label="Start Date" component={TextEditReadOnlyField} />
                                        <Field name="notes" type="text" multiline rows={3} className="form-control" label="Cycle Notes" component={TextEditReadOnlyField} />
                                        
                                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']} sx={{ marginTop: 1, marginBottom: 1 }}>
                                                <DatePicker
                                                    label="Expiry"
                                                    value={formik.values.start}
                                                    onChange={(newValue) => formik.setFieldValue(
                                                        'Start Date', newValue)}
                                                />

                                            </DemoContainer>
                                        </LocalizationProvider> */}
                                        <FormControlLabel sx={{mt: 0.5}} control={<Checkbox checked={isActiveChecked} name="is_active" 
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: colorConfigs.buttons.active,
                                                },
                                            }}
                                            onChange={(e) => {
                                                    // setIsActiveChecked(!isActiveChecked)
                                                    // formik.handleChange(e);
                                                }} className="form-control" />} 
                                                label={
                                                    <Box display="flex" alignItems="center">
                                                      Cycle is
                                                      <Typography 
                                                        fontSize="smaller" 
                                                        sx={{ 
                                                          paddingX: 0.7, 
                                                          border: isActiveChecked ? `2px solid ${colorConfigs.buttons.active}` : `1px solid ${colorConfigs.buttons.inactive}`, 
                                                          borderRadius: 2, 
                                                          color: isActiveChecked ? colorConfigs.buttons.active : colorConfigs.buttons.inactive,
                                                          marginLeft: 1
                                                        }}
                                                      >
                                                        {isActiveChecked ? 'Active' : 'Inactive'}
                                                      </Typography>
                                                    </Box>
                                                  } 
                                                />
                                        </div>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={5} style={{ display: "flex", flexDirection: "column",  }}>
                                <Card sx={{ boxShadow: 10, p:2, height: "100%"}}>
                                    <div className="form-group" style={{display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                    <Typography variant="h6"  sx={{color: colorConfigs.sidebar.color}} gutterBottom>
                                        Dog
                                    </Typography>
                                    <Field name="dog" type="text" className="form-control" label="Dog ID" component={TextEditReadOnlyField} />
                                    <Field name="dog_breed" type="text" className="form-control" label="Dog Breed" component={TextEditReadOnlyField} />
                                    <Field name="dog_name" type="text" className="form-control" label="Dog Name" component={TextEditReadOnlyField} />
                                    <Field name="user" type="text" className="form-control" label="Dog Owner ID" component={TextEditReadOnlyField} />
                                    <Box sx={{display: "flex", justifyContent: "space-between", gap: 2}}>
                                    <FormControlLabel sx={{mt: 0.5}} control={<Checkbox checked={isDogActiveChecked} name="dog_is_active" 
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: colorConfigs.buttons.active,
                                                },
                                            }}
                                            onChange={(e) => {
                                                    // setIsActiveChecked(!isDogActiveChecked)
                                                    // formik.handleChange(e);
                                                }} className="form-control" />} 
                                                label={
                                                    <Box display="flex" alignItems="center">
                                                      Dog is
                                                      <Typography 
                                                        fontSize="smaller" 
                                                        sx={{ 
                                                          paddingX: 0.7, 
                                                          border: isDogActiveChecked ? `2px solid ${colorConfigs.buttons.active}` : `1px solid ${colorConfigs.buttons.inactive}`, 
                                                          borderRadius: 2, 
                                                          color: isDogActiveChecked ? colorConfigs.buttons.active : colorConfigs.buttons.inactive,
                                                          marginLeft: 1
                                                        }}
                                                      >
                                                        {isDogActiveChecked ? 'Active' : 'Inactive'}
                                                      </Typography>
                                                    </Box>
                                                  } 
                                                /> 
                                        <FormControlLabel sx={{mt: 0.5}} control={<Checkbox checked={isDogMemorialisedChecked} name="dog_is_archived" 
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: colorConfigs.buttons.memorialised
                                                },
                                            }}
                                            onChange={(e) => {
                                                    // setIsActiveChecked(!isDogMemorialisedChecked)
                                                    // formik.handleChange(e);
                                                }} className="form-control" />} 
                                                label={
                                                    <Box display="flex" alignItems="center">
                                                      Dog is
                                                      <Typography 
                                                        fontSize="smaller" 
                                                        sx={{ 
                                                          paddingX: 0.7, 
                                                          border: isDogMemorialisedChecked ? `2px solid ${colorConfigs.buttons.memorialised}` : `1px solid ${colorConfigs.buttons.inactive}`, 
                                                          borderRadius: 2, 
                                                          color: isDogMemorialisedChecked ? colorConfigs.buttons.memorialised : colorConfigs.buttons.inactive,
                                                          marginLeft: 1
                                                        }}
                                                      >
                                                        {isDogMemorialisedChecked ? 'Memorialised' : 'Not Memorialised'}
                                                      </Typography>
                                                    </Box>
                                                  } 
                                                />  
                                    </Box> 
                                    </div>
                                    </Card>
                            </Grid>
                         </Grid>
                    </div>
                    <div className="form-group" style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                        <Button
                            className="form-group"
                            onClick={() => handleBackButton()}
                            variant="contained"
                            sx={{
                                position: "relative",
                                m: 1,
                                background: colorConfigs.buttons.bg,
                                color: "white",
                                textTransform: 'none',
                                fontSize: 16,
                            }}
                        >
                            Back
                        </Button>
                        {/* <Button
                            className="form-group"
                            type="submit"
                            variant="contained"
                            disabled={!hasMadeChanges}
                            sx={{
                                type:"submit",
                                position: "relative",
                                m: 1,
                                background: colorConfigs.buttons.bg,
                                color: "white",
                                textTransform: 'none',
                                fontSize: 16,
                                animation: hasMadeChanges ? 'growShrink 0.5s infinite alternate' : 'none',
                                '&.Mui-disabled': {
                                    color: "white", // Text color when disabled
                                    opacity: 0.5,
                                },
                            }}
                        >
                            Save Changes
                        </Button> */}
                    </div>
                </Card>
            </form>
        </FormikProvider>
    )
}

export default CyclesDetailCard;