import { AppBar, Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemText, Paper, Slide, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { isLoggedIn } from '../../redux/features/auth/authService';
import SignInDialog from '../../components/common/SignInDialog';
import axios from 'axios';
import { logout, refreshAccessToken } from '../../redux/features/auth/authSlice';
import { DeviceSessionStorageKey, DeviceTableParameterStorageService } from '../../services/sessionStorage';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import DeviceDetailCard from '../../components/common/cards/DeviceDetailCard';
import DevicesList from '../../components/common/tables/DevicesList';
import colorConfigs from '../../configs/colorConfigs';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';

const loginpage = "/login"

axios.defaults.baseURL = window.env.API_URL;



type Props = {}


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const DevicesPage = (props: Props) => {

const navigate = useNavigate()
const authData = useSelector((state: RootState) => state.auth);
const [isLoading, setIsLoading] = useState<boolean>(false);
const [device, setDevice] = useState<any>(null);
const [popup, setPopup] = useState<any>("");
const [needToRefreshData, setNeedToRefreshData] = useState<any>(false);

const [sorting, setSorting] = useState<any>(() => {
  return DeviceTableParameterStorageService.get(DeviceSessionStorageKey.sorting) || []
});

const [columnVisibility, setColumnVisibility] = useState<any>(() => {
  return DeviceTableParameterStorageService.get(DeviceSessionStorageKey.columnVisibility) || {}
});

const [columnFilters, setColumnFilters] = useState<any>(() => {
  return DeviceTableParameterStorageService.get(DeviceSessionStorageKey.columnFilters) || []
});

const [globalFilter, setGlobalFilter] = useState<string>(() => {
  return DeviceTableParameterStorageService.get(DeviceSessionStorageKey.search) || "";
});

const [pagination, setPagination] = useState<any>(() => {
  return DeviceTableParameterStorageService.get(DeviceSessionStorageKey.pagination) || { pageSize: 1000, pageIndex: 0 }
});

const [showGlobalFilter, setShowGlobalFilter] = useState<any>(() => {
  return DeviceTableParameterStorageService.get(DeviceSessionStorageKey.showSearch) || false
});

const [data, setData] = useState<any>(() => {
  return DeviceTableParameterStorageService.get(DeviceSessionStorageKey.data) || []
});

const [count, setCount] = useState<any>(() => {
  return DeviceTableParameterStorageService.get(DeviceSessionStorageKey.count) || ""
});

const handleSaveButton = () => {
  setDevice("")
  fetchData()
  setPopup("")
}

const handleBackButton = () => {
  setDevice("")
  setPopup("")
}

useEffect(() => {
  if (data.length < 1) {
    fetchData();
  } else {
    if (!needToRefreshData) {
      checkForNewData();
    }
  }
  setNeedToRefreshData(false)
}, [data.length]); 


const fetchData = () => {
  setIsLoading(true);
  axios.get(`api/devices/`, { 
    headers: {
    'Authorization': `Bearer ${authData.access}`
  }
})
  .then(res => {
    // Set Data
    setData(res.data.results);
    console.log(res.data.results)
    DeviceTableParameterStorageService.set(DeviceSessionStorageKey.data, res.data.results)
    DeviceTableParameterStorageService.set(DeviceSessionStorageKey.count, res.data.count)
    setIsLoading(false);
    setNeedToRefreshData(false)
  })
  .catch(err => {
    console.log(err)
  })
}

const checkForNewData = () => { 
  // If new data has been added then alert refresh data button
  axios.get(`api/devices?limit=1`, { 
    headers: {
    'Authorization': `Bearer ${authData.access}`
  }
})
  .then(res => {
    // Set Data
    if (count && count != res.data.count) {
      console.log("count", count, res.data.count)
      // setNeedToRefreshData(true)
      fetchData()
    }
  })
  .catch(err => {
    console.log(err)
  })
}

const handleRemoveAllFilters = () => {
  setGlobalFilter("")
  setShowGlobalFilter(false)
  setColumnFilters([])
  setColumnVisibility({})
  setPagination({ pageSize: 1000, pageIndex: 0 })
  setSorting([])
}

// AUTHENTICATION CHECK
const dispatch = useDispatch<AppDispatch>();
useEffect(() => {
  const checkAuth = async () => {
      if (!authData.isAuthenticated) {
          dispatch(logout());
          navigate('/login');
          return;
      }
      if (!isLoggedIn(authData.access)) {
          await dispatch(refreshAccessToken());
      }
  };
  
  checkAuth();
}, [dispatch, navigate, authData.isAuthenticated, authData.access]);


  return authData.isAuthenticated === false ? (
    <SignInDialog isAuthenticated={authData.isAuthenticated} />
    ) : authData.isAuthenticated ? (
      <div style={{ overflowX: "hidden", maxWidth: `85vw`, minWidth: `85vw`, margin: "auto" }}>
      <div style={{ width: "100%", display: "flex", justifyContent: "space-between"}}>
      <Typography variant="h5" sx={{
            p:0, 
            m:0,
            mb: 1,
            fontSize: '50px',
            fontStretch: "normal",
            fontWeight: "bold",
            lineHeight: "normal",
            letterSpacing: "normal",
            color: colorConfigs.sidebar.color
          }}>
          <PhoneAndroidOutlinedIcon  sx={{
          p:0, 
          mb:-1,
          mr:1,
          fontSize: '50px',
          fontStretch: "normal",
          fontWeight: "bold",
          lineHeight: "normal",
          letterSpacing: "normal",
          color: colorConfigs.sidebar.color,
        }}/>
        Devices
      </Typography> 
    </div>
    <div style={{ display: "flex", height: "100%", width: "100%" }}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{m:0}}
        >
          <Grid item xs={3} sx={{m:0, p:0}}>
          <Box sx={{ m:0, p:2, height: "100%", overflowY: "auto", marginRight: 6, display: 'flex', flexDirection: 'column', overflowX: "hidden", maxWidth: `85vw`, minWidth: `85vw` }}>
                <Box sx={{flex: 1, display: 'flex', flexDirection: 'column', overflowY: 'hidden', boxShadow: 2 }}>
                  <DevicesList
                    data={data}
                    isLoading={isLoading}
                    sorting={sorting}
                    columnVisibility={columnVisibility}
                    columnFilters={columnFilters}
                    pagination={pagination}
                    globalFilter={globalFilter}
                    showGlobalFilter= {showGlobalFilter}
                    setShowGlobalFilter={setShowGlobalFilter}
                    handleRemoveAllFilters={handleRemoveAllFilters}
                    setGlobalFilter={setGlobalFilter}
                    setSorting={setSorting}
                    setColumnVisibility={setColumnVisibility}
                    setColumnFilters={setColumnFilters}
                    setPagination={setPagination}
                    setDevice={setDevice}
                    fetchData={fetchData}
                    setPopup={setPopup}
                    needToRefreshData={needToRefreshData}
                  />
            </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
      <Dialog
            open={(device && popup === "Edit") || popup === "Create"}
            fullScreen
            onClose={() => {setDevice(""); setPopup("")}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Transition}
            sx={{
              '& .MuiDialog-paper': {
                alignItems: 'center',
                justifyContent: 'center',
              },
            }}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p:1, backgroundColor: colorConfigs.tables.headBg, opacity: 0.95}}>
                {popup &&
                <Typography
                      onClick={() => {setDevice(""); setPopup("")}}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "20px",
                        fontWeight: "bold",
                        m: "auto",
                        mt: 0,
                        mb: 0,
                        width: "40%",
                        borderRadius: 1,
                        padding: 1,
                        transition: "width 0.3s ease-in-out"
                      }}
                    >
                     {`${popup} Device`}
                    </Typography>  
                }
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {setDevice(""); setPopup("")}}
                  aria-label="close"
                  sx={{ position: 'absolute', right: 15 }}
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <DialogContent sx={{p:0, minWidth: "100vw", maxWidth: "100vw", backgroundColor: colorConfigs.sidebar.bg}}>
                {(popup) &&
                <DeviceDetailCard device={device} create={popup === "Create"} handleSaveButton={handleSaveButton} handleBackButton={handleBackButton}  />
                }
            </DialogContent>
        </Dialog>
    </div>
    
) : (null);
};

export default DevicesPage;
