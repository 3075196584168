import { Grid } from '@mui/material'
import React from 'react'
import ForgottenPasswordCard from '../../components/common/cards/ForgottenPasswordCard'

type Props = {}

const ForgottenPasswordPage = (props: Props) => {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Grid container style={{ marginLeft: "auto", marginRight: "auto", paddingTop:"1%", paddingBottom: "2%", overflow: "hidden" }}>
          <Grid item xs={12}>
              <div style={{ display: "flex", alignItems: "center", height: "100%", width: "100%" }}>
                  <ForgottenPasswordCard />
              </div>
          </Grid>
      </Grid>
    </div>
  )
}

export default ForgottenPasswordPage