import { AppBar, Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, List, ListItem, ListItemText, Paper, Slide, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { isLoggedIn } from '../../redux/features/auth/authService';
import SignInDialog from '../../components/common/SignInDialog';
import axios from 'axios';
import { logout, refreshAccessToken } from '../../redux/features/auth/authSlice';
import { CycleSessionStorageKey, CycleTableParameterStorageService } from '../../services/sessionStorage';
import { TransitionProps } from '@mui/material/transitions';
import colorConfigs from '../../configs/colorConfigs';
import DashboardSummaryTable from '../../components/common/tables/DashboadSummaryTable';
import assets from '../../assets';
import Top10UsersTable from '../../components/common/tables/Top10UsersTable';
import InterpretationsPieChart from '../../components/graphs/InterpretationsPieChart';
import DashboardCreationTimelineTable from '../../components/common/tables/DashboadCreationTimelineTable';

const loginpage = "/login"

axios.defaults.baseURL = window.env.API_URL;

//  Timeframes
const timeframes = [
    {id: 1, time: "Week to Date", param: "week_to_date"},
    {id: 2, time: "Last 7 Days", param: "last_7_days"}, 
    {id: 3, time: "Month to Date", param: "month_to_date"}, 
    {id: 4, time: "Last 6 Months", param: "last_6_months"}, 
    {id: 5, time: "Year to Date", param: "year_to_date"}, 
    {id: 6, time: "Last 12 Months", param: "last_12_months"},
];

const months = [
  {time: "January", param: "january"},
  {time: "February", param: "february"},
  {time: "March", param: "march"},
  {time: "April", param: "april"},
  {time: "May", param: "may"},
  {time: "June", param: "june"},
  {time: "July", param: "july"},
  {time: "August", param: "august"},
  {time: "September", param: "september"},
  {time: "October", param: "october"},
  {time: "November", param: "november"},
  {time: "December", param: "december"},
];

// Dynamically add Months starting with last month
const currentMonth = new Date().getMonth(); // 0 is January, 11 is December
const orderedMonths = [];
for (let i = currentMonth - 1; i >= 0; i--) {
    orderedMonths.push(months[i]);
}
for (let i = months.length - 1; i >= currentMonth; i--) {
    orderedMonths.push(months[i]);
}
orderedMonths.forEach((month, index) => {
    timeframes.push({id: 7 + index, time: month.time, param: month.param});
});
// Add "Today" at the end
timeframes.push({id: timeframes.length, time: "Today", param: "today"});

const creationTimelineTimeframes = timeframes.filter(timeframe => ![4, 5, 6].includes(timeframe.id));


console.log(timeframes);

type Props = {}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DashboardPage = (props: Props) => {

const navigate = useNavigate()
const authData = useSelector((state: RootState) => state.auth);
const [isLoading, setIsLoading] = useState<boolean>(false);
const [user, setUser] = useState<any>(null);

// Change state to all the new states, add in to session storage
const [data, setData] = useState<any>(() => {
  return CycleTableParameterStorageService.get(CycleSessionStorageKey.data) || []
});

const [top10Users, setTop10Users] = useState<any>([
  {id: ""},{id: ""},{id: ""},{id: ""},{id: ""},{id: ""},{id: ""},{id: ""},{id: ""},{id: ""},
]);
const [totalsSummary, setTotalsSummary] = useState<any>({
    total_active_cycles: "",
    total_active_dogs: "",
    total_active_users: "",
    total_memorialised_dogs: ""
});
const [timeframeSummary, setTimeframeSummary] = useState<any>({
    total_results: "",
    total_active_users: "",
    total_active_cycles: "",
    total_active_dogs: ""
});
const [resultStates, setResultStates] = useState<any>({
    below: "",
    valid: "",
    above: "",
    invalid: ""
})
const [resultClassifications, setResultClassifications] = useState<any>({
    below_baseline: "",
    below_labourimminent: "",
    valid_baseline: "",
    valid_labourimminent: "",
    valid_lhsurge: "",
    valid_ovulationlikely: "",
    valid_progesteronerise: "",
    valid_highprogesterone: "",
    above_highprogesterone: "",
    invalid: "",
});
const [dataCreationTimeline, setDataCreationTimeline] = useState<any[]>([]);
const [top10UserTimeframe, setTop10UserTimeframe] = useState<any>(timeframes[0]);
const [timeframeSummaryTimeframe, setTimeframeSummaryTimeframe] = useState<any>(timeframes[0]);
const [resultClassificationTimeframe, setResultClassificationTimeframe] = useState<any>(timeframes[0]);
const [isDataFetched, setIsDataFetched] = useState<boolean>(false);
const [isInitialDataFetched, setIsInitialDataFetched] = useState<boolean>(false);


useEffect(() => {
  setIsLoading(true);
  if (authData.isAuthenticated == false) {
    navigate("login")
  }  
}, [authData]);


const fetchInitialData = () => {
  // Fetch data button only triggers on first click
    if (!isDataFetched) {
      fetchTotalsSummary()
      fetchTimeframeSummary(timeframes[0])
      fetchTop10Users(timeframes[0])
      fetchResultInterpretations(timeframes[0])
      fetchDataCreationTimeline(timeframes[0])
    }
    setIsDataFetched(true)
}

const OnTimeframeSummaryTimeframeChange =  (timeframeID: any) => {
    fetchTimeframeSummary(timeframes[timeframeID])
}
const OnTop10UserTimeframeChange =  (timeframeID: any) => {
    fetchTop10Users(timeframes[timeframeID])
}

const OnResultClassificationChange =  (timeframeID: any) => {
  fetchResultInterpretations(timeframes[timeframeID])
}

const OnCreationTimelineChange =  (timeframeID: any) => {
  fetchDataCreationTimeline(creationTimelineTimeframes[timeframeID])
}

const fetchTimeframeSummary = (timeframe: any) => {
    const url = `/api/dashboard-timeframe-summary?filter=${timeframe.param}`
    axios.get(url, { 
      headers: {
      'Authorization': `Bearer ${authData.access}`
    }
  })
    .then(res => {
      setTimeframeSummary(res.data);
      // CycleTableParameterStorageService.set(CycleSessionStorageKey.data, res.data.results)
      setIsLoading(false);
      console.log("TimeFrameSummary", timeframeSummaryTimeframe.time, res.data)
    })
    .catch(err => {
      console.log(err)
    })
  }

const fetchTop10Users = (timeframe: any) => {
  const url = `/api/dashboard-top-users?number=10&filter=${timeframe.param}`
  axios.get(url, { 
    headers: {
    'Authorization': `Bearer ${authData.access}`
  }
})
  .then(res => {
    setTop10Users([]);
    setTop10Users(res.data);
    // CycleTableParameterStorageService.set(CycleSessionStorageKey.data, res.data.results)
    setIsLoading(false);
    console.log("top10users", top10UserTimeframe.time, res.data)
  })
  .catch(err => {
    console.log(err)
  })
}

const fetchTotalsSummary = () => {
    const url = `/api/dashboard-totals-summary/`
    axios.get(url, { 
      headers: {
      'Authorization': `Bearer ${authData.access}`
    }
  })
    .then(res => {
      setTotalsSummary(res.data);
      // CycleTableParameterStorageService.set(CycleSessionStorageKey.data, res.data.results)
      setIsLoading(false);
      console.log("TotalsSummary", res.data)
    })
    .catch(err => {
      console.log(err)
    })
  }

const fetchResultInterpretations = (timeframe: any) => {
  const url = `/api/dashboard-result-classification?filter=${timeframe.param}`
  axios.get(url, { 
    headers: {
    'Authorization': `Bearer ${authData.access}`
  }
})
  .then(res => {
    setResultStates(res.data.state_totals);
    setResultClassifications(res.data.classification_totals);
    // CycleTableParameterStorageService.set(CycleSessionStorageKey.data, res.data.results)
    setIsLoading(false);
    console.log("Result Classifications", timeframe.param, res.data)
  })
  .catch(err => {
    console.log(err)
  })
}

const fetchDataCreationTimeline = (timeframe: any) => {
  const url = `/api/dashboard-timeline?filter=${timeframe.param}`
  axios.get(url, { 
    headers: {
    'Authorization': `Bearer ${authData.access}`
  }
})
  .then(res => {
    setDataCreationTimeline(res.data.summaries);
    // CycleTableParameterStorageService.set(CycleSessionStorageKey.data, res.data.results)
    setIsLoading(false);
    console.log("Creation Timeline", timeframe.param, res.data.summaries)
  })
  .catch(err => {
    console.log(err)
  })
}

// AUTHENTICATION CHECK
const dispatch = useDispatch<AppDispatch>();
useEffect(() => {
  const checkAuth = async () => {
      if (!authData.isAuthenticated) {
          dispatch(logout());
          navigate('/login');
          return;
      }
      if (!isLoggedIn(authData.access)) {
          await dispatch(refreshAccessToken());
      }
  };
  
  checkAuth();
}, [dispatch, navigate, authData.isAuthenticated, authData.access]);


  return authData.isAuthenticated === false ? (
    <SignInDialog isAuthenticated={authData.isAuthenticated} />
    ) : authData.isAuthenticated ? 

    <Box sx={{ paddingX: 4, paddingTop: 1, paddingBottom: 10, overflowX: "hidden", maxWidth: `90vw`, minWidth: `90vw`, margin: "auto" }}>
    <Grid container spacing={3.5}>
      {/* Summary Card (Panels 1 & 4) */}
      <Grid item xs={12} sm={3.25} sx={{ height: "60vh" }}>
        <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
        <Card sx={{ boxShadow: 10, width: "100%", height: "100%", border: "2px solid", borderColor: "rgba(134, 88, 155, 0.4)", borderRadius: 2, opacity: isDataFetched ? 1 : 0.5, transition: 'opacity 0.5s ease-in-out' }}> 
            <Typography variant="h6" align="center" sx={{ p: 0.5, paddingBottom: 0, fontWeight: "bold", background: "rgba(134, 88, 155, 0.9)" , color: "#fff"}}>
              Summary
            </Typography>
            <DashboardSummaryTable totals={totalsSummary} timeframeSummary={timeframeSummary} timeframes={timeframes} OnTimeframeSummaryTimeframeChange={OnTimeframeSummaryTimeframeChange} />
          </Card>
        </div>
      </Grid>
      {/* borderColor: "rgba(134, 88, 155, 0.9)", */}
  
        {/* Result Interpretations and Logo (Panel 2 & 5) */}
        <Grid item xs={12} sm={5.5} sx={{ height: "60vh" }}>
            <Grid container spacing={3.5} direction="column" sx={{height: "60vh" }}>

              {/* Result Interpretations (Panel 2) */}
              <Grid item xs={6} sm={9} sx={{ height: "31.5vh", width: "40.1vw" }}>
                <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                  <Card sx={{ boxShadow: 10, width: "100%", height: "100%", border: "2px solid", borderColor: "rgba(134, 88, 155, 0.4)", borderRadius: 2, opacity: isDataFetched ? 1 : 0.5, transition: 'opacity 0.5s ease-in-out'  }}> 
                    <Typography variant="h6" align="center" sx={{ p: 0.5, paddingBottom: 0, fontWeight: "bold", background: "rgba(134, 88, 155, 0.9)" , color: "#fff"}}>
                        Results
                    </Typography>
                    <InterpretationsPieChart resultStates={resultStates} resultClassifications={resultClassifications} timeframes={timeframes} OnResultClassificationChange={OnResultClassificationChange} />
                  </Card>
                </div>
              </Grid>

              {/* Logo (Panel 5) */}
              <Grid item xs={6} sm={3} sx={{ height: "15vh", width: "40.1vw"  }}>
      <div
        onClick={fetchInitialData}
        style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }}
            >
              <Card
                sx={{
                  position: "relative",
                  boxShadow: isDataFetched
                    ? "inset 5px 5px 15px rgba(0, 0, 0, 0.2), inset -5px -5px 15px rgba(255, 255, 255, 0.5)"
                    : 10,
                  // p: 1,
                  width: "70%",
                  height: "110%",
                  borderRadius: 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: isDataFetched ? `inherit` : colorConfigs.sidebar.color,
                  color: '#fff',
                  padding: isDataFetched ? 1 : 5,
                  animation: !isDataFetched ? 'growShrink 0.5s infinite alternate' : 'none',  
                  transition: 'box-shadow 3s ease-in-out, border 3s ease-in-out, padding 3s ease-in-out',                
                }}
              >
                  <img
                    src={assets.images.blLogo}
                    alt="Logo"
                    style={{
                      maxHeight: '80%',
                      maxWidth: '80%',
                      opacity: isDataFetched ? 1 : 0,
                      transition: 'opacity 4.5s ease-in-out, transform 2s ease-in-out'
                    }}
                    className="fetched-logo"
                  />
                  {!isDataFetched &&             
                      <Typography
                        variant="h5"
                        style={{
                          fontWeight: "bold",
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          pointerEvents: 'none',
                          // color: colorConfigs.sidebar.color, // Change this to match your design
                        }}
                      >
                        Fetch Data
                      </Typography>
                      }
              </Card>
            </div>
            <style>{`
              @keyframes growShrink {
                0% {
                  transform: scale(1);
                }
                100% {
                  transform: scale(1.05);
                }
              }
              .fetched-logo {
                transition: transform 0.3s ease-in-out;
              }
              .fetched-logo:hover {
                transform: scale(1.1);
              }
            `}</style>
          </Grid>     
            </Grid>
        </Grid>
        
  
  
          {/* Top 10 Users Card (Panels 3 & 6) */}
          <Grid item xs={12} sm={3.25} sx={{ height: "60vh" }}>
          <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            <Card sx={{ boxShadow: 10, width: "100%", height: "100%", border: "2px solid", borderColor: "rgba(134, 88, 155, 0.4)", borderRadius: 2, opacity: isDataFetched ? 1 : 0.5, transition: 'opacity 0.5s ease-in-out'  }}> 
              <Typography variant="h6" align="center" sx={{ p: 0.5, paddingBottom: 0, fontWeight: "bold", background: "rgba(134, 88, 155, 0.9)" , color: "#fff"}}>
                Most Active Users
              </Typography>
              <Top10UsersTable  top10Users={top10Users} timeframes={timeframes} OnTop10UserTimeframeChange={OnTop10UserTimeframeChange}/>
            </Card>
            </div>
          </Grid>
  
          {/* Data Creation Timeline Card (Panels 7, 8 & 9) */}
          <Grid item xs={12} sx={{ height: "35vh" }}>
          <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            <Card sx={{ boxShadow: 10, width: "100%", height: "100%", border: "2px solid", borderColor: "rgba(134, 88, 155, 0.4)", borderRadius: 2, opacity: isDataFetched ? 1 : 0.5, transition: 'opacity 0.5s ease-in-out'  }}> 
              <Typography variant="h6" align="center" sx={{ p: 0.5, paddingBottom: 0, fontWeight: "bold", background: "rgba(134, 88, 155, 0.9)" , color: "#fff"}}>
                Recent Activity Timeline
              </Typography>
              <DashboardCreationTimelineTable data={dataCreationTimeline} timeframes={creationTimelineTimeframes} OnCreationTimelineChange={OnCreationTimelineChange}/>
            </Card>
            </div>
          </Grid>
        </Grid>
      </Box>
    
 : (null);
};

export default DashboardPage;