import { alpha, AppBar, Button, createTheme, Dialog, DialogContent, Divider, IconButton, Link, Menu, MenuItem, MenuProps, Slide, styled, Toolbar, Typography } from '@mui/material';
import React, {useState, useEffect} from 'react';
import assets from '../../../assets';
import LogoutIcon from '@mui/icons-material/Logout';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";
import { Link as routerLink } from "react-router-dom";
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from '../../../redux/store';
import { logout } from '../../../redux/features/auth/authSlice';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import colorConfigs from '../../../configs/colorConfigs';
import EditProfileCard from '../cards/EditProfileCard';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';

type Props = {}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => {
      return ({
          '& .MuiPaper-root': {
              borderRadius: 6,
              marginTop: theme.spacing(1),
              minWidth: 180,
              color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
              boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
              '& .MuiMenu-list': {
                  padding: '4px 0',
              },
              '& .MuiMenuItem-root': {
                  '& .MuiSvgIcon-root': {
                      fontSize: 18,
                      color: theme.palette.text.secondary,
                      marginRight: theme.spacing(1.5),
                  },
                  '&:active': {
                      backgroundColor: alpha(
                          theme.palette.primary.main,
                          theme.palette.action.selectedOpacity
                      ),
                  },
              },
          },
      })
  });

export default function ProfileDropdown(props: Props): any {
  const [user, setUser] = useState<any>('')
  const [editProfile, setEditProfile] = useState<any>('')
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const authData = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

// GET USER FROM REDUX
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    // Fetch user data
    
    setUser(authData.user)            

    setAnchorEl(event.currentTarget);
  };


  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  }
  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleSaveButton = () => {
    setAnchorEl(null);
    setEditProfile(false);
  }
  
  const handleBackButton = () => {
    setEditProfile(false)
  }


  return (
    <div>
      <IconButton
      size="medium"
      aria-label="profile icon"
      sx={{ mr: -1}}
      onClick={handleClick}>
        <AccountCircleOutlinedIcon sx={{ fontSize: "60px", color: colorConfigs.sidebar.color}}/>
      </IconButton>
      
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem disabled sx={{color:"black"}} >
            User: {user.email}
        </MenuItem>
        <MenuItem disabled sx={{color:"black"}} >
            Portal Version: 0.1.0
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={() => setEditProfile(true)} >
          <EditIcon />
            Edit Profile
        </MenuItem>
        <MenuItem onClick={handleLogout} component={routerLink} to="/auth/login">
          <LogoutIcon />
          Logout
        </MenuItem>
      </StyledMenu>

      <Dialog
            open={editProfile}
            fullScreen
            onClose={() => {setEditProfile(!editProfile)}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Transition}
            sx={{
              '& .MuiDialog-paper': {
                alignItems: 'center',
                justifyContent: 'center',
              },
            }}
          >
            <AppBar sx={{ position: 'relative' }}>
              <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p:1, backgroundColor: colorConfigs.tables.headBg, opacity: 0.95}}>
                <Typography
                      onClick={() => {setEditProfile(!editProfile)}}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "20px",
                        fontWeight: "bold",
                        m: "auto",
                        mt: 0,
                        mb: 0,
                        width: "40%",
                        borderRadius: 1,
                        padding: 1,
                        transition: "width 0.3s ease-in-out"
                      }}
                    >
                     {`Edit Profile`}
                    </Typography>  
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {setEditProfile(!editProfile)}}
                  aria-label="close"
                  sx={{ position: 'absolute', right: 15 }}
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <DialogContent sx={{p:0, minWidth: "100vw", maxWidth: "100vw", backgroundColor: colorConfigs.sidebar.bg}}>
                <EditProfileCard user={user} create={false} handleSaveButton={handleSaveButton} handleBackButton={handleBackButton}  />
            </DialogContent>
        </Dialog>
    </div>
  );
};
