import axios from 'axios';
import { store } from '../../store';
import { refreshAccessToken, logout } from './authSlice';
import { isLoggedIn } from './authService';

// Create an Axios instance
const axiosInstance = axios.create({
    baseURL: window.env.API_URL, // Use your API base URL
});

// Request interceptor to add the access token to headers
// If the refresh token is invalid then L
axiosInstance.interceptors.request.use(async (config) => {
    const state = store.getState();
    const access = state.auth.access;

    if (access && !isLoggedIn(access)) {
        try {
            await store.dispatch(refreshAccessToken()).unwrap();
            config.headers['Authorization'] = `Bearer ${store.getState().auth.access}`;
        } catch (error) {
            store.dispatch(logout());
            window.location.href = '/login';
            return Promise.reject(error);
        }
    } else if (access) {
        config.headers['Authorization'] = `Bearer ${access}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

// Response interceptor to handle 403 errors and refresh token
axiosInstance.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
            await store.dispatch(refreshAccessToken()).unwrap();
            originalRequest.headers['Authorization'] = `Bearer ${store.getState().auth.access}`;
            return axiosInstance(originalRequest);
        } catch (refreshError) {
            store.dispatch(logout());
            window.location.href = '/login';
            return Promise.reject(refreshError);
        }
    }
    return Promise.reject(error);
});

export default axiosInstance;
