const assets = {
    images: {
        logoinitial: require("./images/logoinitial.png"),
        profile: require("./images/profileicon.png"),
        emailicon: require("../assets/images/EmailIcon.png"),
        logo: require("../assets/images/logo.png"),
        novarumLogo: require("../assets/images/novarumLogo.png"),
        normalresult: require("./images/normal_result.png"),
        moderateresult: require("./images/moderate_result.png"),
        highresult: require("./images/high_result.png"),
        invalidresult: require("./images/invalid_result.png"),
        blLogo: require("./images/bl_logo.png"),
        blSmallDog: require("./images/bl.png"),
    },
    actions: {},
    fonts: {}
}

export default assets;