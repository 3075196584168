import React from 'react'
import { FieldProps, getIn } from 'formik'
import { Box, Checkbox, InputAdornment, MenuItem, Select, TextField, TextFieldProps, Typography } from '@mui/material'
import colorConfigs from '../../../configs/colorConfigs';


export const TScoreField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="TScore"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
            sx = {{
                fontSize: 18
            }}
            InputLabelProps={{
                style: {
                    fontSize: 16,
                    fontWeight: 1000
                },
                shrink: true 
            }}
        />
    )
}
export const TPeakField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="TPeak"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
            sx = {{
                fontSize: 18
            }}
            InputLabelProps={{
                style: {
                    fontSize: 16,
                    fontWeight: 1000
                },
                shrink: true 
            }}
        />
    )
}
export const TCRatioField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="TCRatio"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
            sx = {{
                fontSize: 18
            }}
            InputLabelProps={{
                style: {
                    fontSize: 16,
                    fontWeight: 1000
                },
                shrink: true 
            }}
        />
    )
}
export const CScoreField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="CScore"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                
            }}
            sx = {{
                fontSize: 18
            }}
            InputLabelProps={{
                style: {
                    fontSize: 16,
                    fontWeight: 1000
                },
                shrink: true 
            }}
        />
    )
}
export const CPeakField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="standard"
            label="CPeak"
            margin="normal"
            autoFocus
            InputProps={{
                readOnly: true,
                disableUnderline: true,  
            }}
            sx = {{
                fontSize: 18
            }}
            InputLabelProps={{
                style: {
                    fontSize: 16,
                    fontWeight: 1000
                },
                shrink: true 
            }}
            
        />
    )
}

export const TextEditField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="outlined"
            margin="normal"
            autoFocus
        />
    )
}

export const TextEditReadOnlyField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="outlined"
            margin="normal"
            autoFocus

            InputProps={{
                readOnly: true,
            }}
            sx={{
              }}
            InputLabelProps={{
                style: {
                },
                shrink: true 
            }}
        />
    )
}

export const ResultDetailReadOnlyField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="outlined"
            margin="normal"
            autoFocus

            InputProps={{
                readOnly: true,
            }}
            sx={{
              }}
            InputLabelProps={{
                style: {
                    fontSize:"18px",
                    marginLeft: "-4px"
                },
                shrink: true 
            }}
        />
    )
}


interface FormVisualResultsFieldProps extends FieldProps {
    visualResult: string;
    resultColor: string;
  }

export const FormVisualResultsField: React.FC<FormVisualResultsFieldProps & TextFieldProps> = ({
    visualResult,
    resultColor,
  ...props
}) => {
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);

  const { error, helperText, field, form, ...rest } = props;
  return (
<TextField
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? (isTouched && errorMessage ? errorMessage : undefined)}
      {...rest}
      {...field}
      fullWidth
      margin="normal"
      autoFocus
      sx= {{
        }}
        InputLabelProps={{
            style: {
                fontSize:"18px",
                marginLeft: "-4px"
            },
            shrink: true 
        }}
      InputProps={{
        sx: {
            paddingY: "5px"
        },
        readOnly: true,
        startAdornment: (
          <InputAdornment position="start" style={{ width: "100%", marginRight: 'auto', marginLeft: 'auto', height: '100%' }}>
            <Box sx={{minWidth: "100%", backgroundColor: resultColor, p:0.5, fontWeight: "bold", display: "flex", justifyContent: "center", borderRadius: 2, overflow: "auto"}}>
                <Typography variant="h6" sx={{minWidth: "5em", textAlign:"center", fontWeight: "bold"}}>{visualResult}</Typography>
            </Box>
          </InputAdornment>
        ),
      }}
    />
  );
};


interface TextAdornmentReadOnlyFieldProps extends FieldProps {
    status: boolean;
    id: string;
  }
export const TextAdornmentReadOnlyField: React.FC<TextAdornmentReadOnlyFieldProps & TextFieldProps>= ({
    status,
    id,
  ...props
}) => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="outlined"
            margin="normal"
            autoFocus

            InputProps={{
                sx:{
                },
                readOnly: true,
                startAdornment: (
                    <InputAdornment position="start" style={{marginRight: 10}}>
                        <Typography variant="body1" sx={{p:0, m:0, minWidth: "2em", color: "#000"}}>
                        {id}
                        </Typography>
                   </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end" style={{}}>
                        {status ? <Typography sx={{ fontSize:"10px", marginLeft: 1, paddingX:0.7, border: `1.5px solid ${colorConfigs.buttons.active}`, borderRadius: 2, color: colorConfigs.buttons.active }} >Active</Typography> : <Typography sx={{ fontSize:"10px", marginLeft: 0.5, paddingX:0.7, border: `1.5px solid ${colorConfigs.buttons.inactive}`, borderRadius: 2, color: colorConfigs.buttons.inactive }} >Inactive</Typography> }
                   </InputAdornment>
                )
            }}
            sx={{
              }}
            InputLabelProps={{
                style: {
                    fontSize:"18px",
                    marginLeft: "-3px"
                },
                shrink: true 
            }}
        />
    )
}

interface ImageFieldProps extends FieldProps {
    image: string;
  }

export const ImageField: React.FC<ImageFieldProps & TextFieldProps> = ({
    image,
  ...props
}) => {
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);

  const { error, helperText, field, form, ...rest } = props;
  return (
<TextField
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? (isTouched && errorMessage ? errorMessage : undefined)}
      {...rest}
      {...field}
      fullWidth
      margin="normal"
      autoFocus
      multiline
      rows={1}
      sx = {{
        }}
        InputLabelProps={{
            style: {
                fontSize:"18px",
                marginLeft: "-4px"
            },
            shrink: true 
        }}
      InputProps={{
        sx: {
            p:3
        },
        readOnly: true,
        startAdornment: (
          <InputAdornment position="start" style={{ marginRight: 'auto', marginLeft: 'auto', height: '100%' }}>
             <img title="test" src={image} alt="test image" width="180px" height="60px" />
          </InputAdornment>
        ),
      }}
    />
  );
};

export const NotesField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="outlined"
            margin="normal"
            autoFocus
            multiline
            rows={3}
            InputProps={{
                readOnly: true,
                disableUnderline: true, 
            }}
            sx = {{
            }}
            InputLabelProps={{
                style: {
                    fontSize:"18px",
                    marginLeft: "-4px"
                },
                shrink: true 
            }}
        />
    )
}

export const TextClassificationReadOnlyField: React.FC<FieldProps & TextFieldProps> = props => {
    const isTouched = getIn(props.form.touched, props.field.name)
    const errorMessage = getIn(props.form.errors, props.field.name)

    const { error, helperText, field, form, ...rest } = props

    return (
        <TextField
            //   variant="outlined"
            error={error ?? Boolean(isTouched && errorMessage)}
            helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
            {...rest}
            {...field}
            fullWidth
            variant="outlined"
            margin="normal"
            autoFocus

            InputProps={{
                readOnly: true,
                sx: {
                    p:"5px"
                },
            }}
            sx={{
              }}
            InputLabelProps={{
                style: {
                    fontSize:"18px",
                    marginLeft: "-4px"
                },
                shrink: true 
            }}
        />
    )
}