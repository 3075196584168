import { Box, Divider, Drawer, List, Stack, Toolbar } from "@mui/material";
import assets from "../../assets";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import appRoutes from "../../routes/appRoutes";
import SidebarItem from "./SidebarItem";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { useState } from "react";



const Sidebar = () => {
  const [isHovered, setIsHovered] = useState(false);
  const authData = useSelector((state: RootState) => state.auth);

  return ( 
    <Drawer
      variant="permanent"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        width: isHovered ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.halfwidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          transition: "width 0.3s",
          boxShadow: '0px 7px 10px 5px rgba(50, 50, 50, 0.6)',
          width: isHovered ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.halfwidth,
          boxSizing: "border-box",
          borderRight: "0px",
          fontWeight: "bold",
          backgroundColor: colorConfigs.sidebar.bg,
          color: colorConfigs.sidebar.color,
          opacity: 0.96
        }
      }}
    >
      <List disablePadding>
        <Toolbar sx={{marginBottom: "20px", height: "100px" }}>
          <Stack
            sx={{ width: "100%", height: "80px" }}
            direction="row"
            justifyContent="center"
          >
            <Box
              component="img"
              sx={{
                height: "100%",
                width: "auto",
                maxHeight: "80px",
                transition: "all 0.3s ease",
                backgroundColor: colorConfigs.sidebar.bg,
                paddingTop: isHovered ? 1 : 2,
                paddingBottom: isHovered ? 2 : 3.5,
                borderRadius: 1,
                marginTop: { xs: 1, md: 2 },
              }}
              alt="BellyLabs Logo"
              src={ isHovered ? assets.images.blLogo : assets.images.blSmallDog}
            />
          </Stack>
        </Toolbar>
        {appRoutes.map((route:any, index:any) => {
          if (!authData.isAuthenticated) return null
          if (route.sidebarProps && authData !== undefined) {
            return <SidebarItem item={route} key={index} isHovered={isHovered}  />;
          }
          return null; 
            })}
      </List>
    </Drawer>
  )
}

export default Sidebar;