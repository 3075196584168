import ForgottenPasswordPage from '../../pages/auth/ForgottenPasswordPage'

type Props = {}

const ForgotPasswordLayout = (props: Props) => {
  return (
    <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
      <ForgottenPasswordPage />
    </div>
  )
}

export default ForgotPasswordLayout