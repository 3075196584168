import { Card, Grid, Typography, Button, FormControlLabel, Checkbox, TextField, Box, Alert, AlertTitle } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Field, FormikProvider, useFormik } from 'formik';

import * as Yup from "yup";
import axios from 'axios';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import {  TextEditField, TextEditReadOnlyField } from '../fields/fields';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import colorConfigs from '../../../configs/colorConfigs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { fetchUserProfile } from '../../../redux/features/auth/authSlice';
import axiosInstance from '../../../redux/features/auth/axiosAuthHelper';

interface UserDetailProps {
  user: any;
  create: boolean;
  handleSaveButton: () => void;
  handleBackButton: () => void;
}

axios.defaults.baseURL = window.env.API_URL;

const loginpage = "/auth/login"

const phoneRegEx = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const UserDetailCard: React.FC<UserDetailProps> = ({user, create, handleSaveButton, handleBackButton}) => {
    const authData = useSelector((state: RootState) => state.auth);
    const [errors, setErrors] = useState<string | null>(null);
    const [initialValues, setInitialValues] = useState<any>({})
    const [updatedValues, setUpdatedValues] = useState<any>({})
    const [hasMadeChanges, setHasMadeChanges] = useState<boolean>(false)
    const [isActiveChecked, setIsActiveChecked] = useState(false);
    // const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch<AppDispatch>();

    console.log("create", create)

    const validationSchema = () => {
        return Yup.object().shape({
            email: Yup.string().required("This field is required"),
        });
    }


    const formik = useFormik({
        enableReinitialize: !create,
        validateOnChange: false,
        validateOnBlur: false,
        initialValues:
            (!create && user) ? {
                id: user.id,
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                date_joined: user.date_joined ? dayjs(user.date_joined).format('YYYY-MM-DD HH:mm:ss') : null, // Cant edit - Set to null if no date
                last_login: user.last_login ? dayjs(user.last_login).format('YYYY-MM-DD HH:mm:ss') : null, // Cant edit - Set to null if no date
                user_type: user.user_type, // Cant edit - Create must be portal user
                units: user.units,
                address: user.address,
                country: user.country,
                locale: user.locale,
                image: user.image,
                total_dogs: user.total_dogs, //Cant edit
                total_active_dogs: user.total_active_dogs, //Cant edit
                total_cycles: user.total_cycles, //Cant edit
                total_results: user.total_results, //Cant edit
                is_active: user.is_active,
            }
            : {
                id: '',
                first_name: '',
                last_name: '',
                email: '',
                date_joined: dayjs(),
                last_login: dayjs(),
                user_type: '',
                units: '',
                address: '',
                country: '',
                locale: '',
                image: null,
                total_dogs: '',
                total_active_dogs: '',
                total_cycles: '',
                total_results: '',
                is_active: true
            },
        validationSchema,
        onSubmit: async (values) => {

            const data = new FormData();  // Multipart Form Data

            // Append form values to FormData
            data.append('first_name', values.first_name);
            data.append('last_name', values.last_name);
            // data.append('units', values.units);
            data.append('email', values.email);
            data.append('address', values.address);
            data.append('country', values.country);
            // data.append('is_active', values.is_active);
        
            const url = `/api/profile/`
            const method = 'patch'

            try {
                console.log("data", data)
                await axiosInstance ({
                    method,
                    url,
                    data,
                    headers: {
                        'Authorization': `Bearer ${authData.access}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setErrors(null);
                console.log("User successfully updated")
                await dispatch(fetchUserProfile()).unwrap()
                handleSaveButton() // reset states and close popup
            } catch(error: any) {
                if (error?.response?.status === 400) {
                    const firstErrorItem = Object.keys(error.response.data)[0];
                    setErrors(`${firstErrorItem}: ${error.response.data[firstErrorItem]}`);
                } else if (error?.response?.status === 403) {
                    const firstErrorItem = Object.keys(error.response.data)[0];
                    setErrors(`${error.response.data[firstErrorItem]}`);
                } else{
                    const errorText = `Unknown error updating User`;
                    setErrors(errorText);
                    console.error(errorText, error);
                }
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },
    });

    //  Set checkbox state
    useEffect(() => {
        setIsActiveChecked(formik.values.is_active)
    }, [user, formik.values.is_active]);

  

    // Effect to set initial form values from user to check for form changes
    useEffect(() => {
        setTimeout(() => {
            setInitialValues(formik.initialValues);
            setUpdatedValues(formik.initialValues);
        }, 500);
        setHasMadeChanges(false);
    }, [user]);

    // Update the updatedValues state when formik values change
    useEffect(() => {
        setUpdatedValues(formik.values);
    }, [formik.values]);

    // Function to check if form has changed so the save button becomes active on edit
    const hasFormChanged = (flatModel: Record<string, any>, formValues: Record<string, any>): boolean => {
        for (const key in formValues) {
            if (key === "expiry_date") {
                const formDate = dayjs(formValues[key]).format('YYYY-MM-DD');
                const initialDate = create ? dayjs(flatModel[key]).format('YYYY-MM-DD') : dayjs(formValues[key]).format('YYYY-MM-DD');
                if (formDate !== initialDate) {
                    console.log("Changed Date", formDate, initialDate)
                    return true;
                }
            } else {
                if (formValues[key]!= flatModel[key]) {
                    console.log("Changed Data", formValues[key], flatModel[key])
                    return true;
                }
            }
        }
        return false;
    };
            
    // Use Effect to check if any changes ahve been made to the form on Edit
    useEffect(() => {
        if (!create && user) {
            if (Object.keys(initialValues).length > 0 && Object.keys(updatedValues).length > 0) {
                const formChanged = hasFormChanged(initialValues, updatedValues);
                setHasMadeChanges(formChanged);
            }
        }
    }, [updatedValues, initialValues, user]);
    
    // Use Effect set save button active on load if Create and if changes are made on Edit
    useEffect(() => {
        if (create) {
            setHasMadeChanges(true);
        } else {
            setHasMadeChanges(false);
        }
    }, [create]);

    return (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} style={{overflow: 'auto'}}>
            <Card sx={{ height: "100%", boxShadow: 10, p:2, marginX: 5, marginY: 3, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            {errors && (
                <Alert severity="error" style={{ width: "59.5%" }}>
                    <AlertTitle>Error</AlertTitle>
                    {errors}
                </Alert>
            )}
            <div style={{width: "100%", height: "100%", margin: "auto", padding: "1em", display: "flex", alignItems: "center"}}>
                <Grid container spacing={5} alignItems="top" justifyContent="center" style={{ minWidth: "100%", padding: 5 }}>
                            <Grid item xs={12} md={5} style={{ display: "flex", flexDirection: "column",  }}>
                                <Card sx={{ boxShadow: 10, p:2, height: "100%"}}>
                                    <div className="form-group" style={{display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                        <Typography variant="h6" sx={{ color: colorConfigs.sidebar.color}} gutterBottom>                                            
                                            My Profile
                                        </Typography> 
                                        {!create && <Field name="id" disableds type="text" className="form-control" label="User ID" component={TextEditReadOnlyField}  />}
                                        <Box sx={{display: "flex", justifyContent: "space-between", gap: 2}}>
                                            <Field name="first_name" type="text" className="form-control" label="First Name" component={TextEditField} />
                                            <Field name="last_name" type="text" className="form-control" label="Last Name" component={TextEditField} />
                                        </Box>
                                        <Field name="email" type="text" className="form-control" label="Email*" component={TextEditField} />
                                        <Field name="address" type="text" className="form-control" label="Address" component={TextEditField} />
                                        <Field name="country" type="text" className="form-control" label="Country" component={TextEditField} />
                                    </div>
                                </Card>
                            </Grid>
                            </Grid>
                    </div>
                    <div className="form-group" style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                        <Button
                            className="form-group"
                            onClick={() => handleBackButton()}
                            variant="contained"
                            sx={{
                                position: "relative",
                                m: 1,
                                background: colorConfigs.buttons.bg,
                                color: "white",
                                textTransform: 'none',
                                fontSize: 16,
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            className="form-group"
                            type="submit"
                            variant="contained"
                            disabled={!hasMadeChanges}
                            sx={{
                                type:"submit",
                                position: "relative",
                                m: 1,
                                background: colorConfigs.buttons.bg,
                                color: "white",
                                textTransform: 'none',
                                fontSize: 16,
                                animation: hasMadeChanges ? 'growShrink 0.5s infinite alternate' : 'none',
                                '&.Mui-disabled': {
                                    color: "white", // Text color when disabled
                                    opacity: 0.5,
                                },
                            }}
                        >
                            Save Changes
                        </Button>
                    </div>
                </Card>
            </form>
        </FormikProvider>
    )
}

export default UserDetailCard;