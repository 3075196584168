import React, { ReactNode } from 'react';
import './App.css';
import { routes } from "./routes";
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from './components/layout/MainLayout';
import HomePage from './pages/home/DashboardPage';
import LoginLayout from './components/layout/LoginLayout';
import LoginPage from './pages/auth/loginPage';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store';
import ActivationStatusPage from './pages/auth/ActivationStatusPage';
import ActivationStatusLayout from './components/layout/ActivationStatusLayout';
import ForgotPasswordLayout from "./components/layout/ForgotPasswordLayout";
import ForgottenPasswordPage from "./pages/auth/ForgottenPasswordPage";
import ResetPasswordLayout from "./components/layout/ResetPasswordLayout";
import ResetPasswordPage from "./pages/auth/ResetPasswordPage";

const loginpage = "/login";

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const authData = useSelector((state: RootState) => state.auth);
  return authData.isAuthenticated ? <>{children}</> : <Navigate to={loginpage} replace />;
};

function App() {
  const authData = useSelector((state: RootState) => state.auth);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ProtectedRoute><MainLayout /></ProtectedRoute>}>
          <Route index element={<HomePage />} />
          {routes}
        </Route>
        <Route path="/login" element={<LoginLayout />}>
          <Route index element={<LoginPage />} />
        </Route>
        <Route path="/activation-status" element={<ActivationStatusLayout />}>
          <Route path=":token" element={<ActivationStatusPage />} />
        </Route>
        <Route path="/auth/forgot-password" element={<ForgotPasswordLayout />}>
          <Route index element={<ForgottenPasswordPage />} />
        </Route>
        <Route path="/accounts/reset/:uidb64/:token" element={<ResetPasswordLayout />}>
          <Route index element={<ResetPasswordPage />} />
        </Route>
        <Route path="*" element={<Navigate to={authData.isAuthenticated ? "/" : loginpage} replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;